import { Notifications, Linking } from "expo";
import * as SplashScreen from "expo-splash-screen";
import { Asset } from "expo-asset";
import {
  Ionicons,
  Feather,
  MaterialIcons,
  Foundation,
} from "@expo/vector-icons";
import * as Font from "expo-font";
import Constants from "expo-constants";
import * as Analytics from "expo-firebase-analytics";
import * as Permissions from "expo-permissions";
import React, { useEffect, useState, useRef } from "react";
import { Platform } from "react-native";

import firebaseSDK from "utils/firebase";
import { Logger } from "utils/Logger";
import axios from "utils/axios";
import { isMobilePlatform, isWeb } from "utils/helpers";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { API_HOST } from "utils/constants";
import { useGetAuth } from "redux/selectors";
import { isSignedUp, signInWithMagicLink } from "utils/auth";
import {
  isGoogleDefaultImage,
  isSnapHabitDefaultImage,
  generateDefaultProfilePictureUrl,
} from "utils/user";

import { chatIcon, groupIcon } from "assets/images";
import { LoginError } from "components/Login/LoginError";
import { Loading } from "components";
import {
  identify,
  logNotificationPermissionAsk,
  logAppStartupNotificationStatus,
} from "utils/analytics";

const IMAGES = [groupIcon, chatIcon];
const FONTS = [
  Ionicons.font,
  Feather.font,
  MaterialIcons.font,
  Foundation.font,
  {
    OpenSans: require("assets/fonts/OpenSans-Regular.ttf"),
    "OpenSans-SemiBold": require("assets/fonts/OpenSans-SemiBold.ttf"),
  },
];

const loadAssets = async () => {
  const fontPromises = FONTS.map(font => Font.loadAsync(font));

  //@ts-ignore
  return Promise.all([Asset.loadAsync(IMAGES), ...fontPromises]);
};

type UserContext = {
  // consumed by App.tsx for loading indicator while handing deeplink
  isDeepLinkHandled: boolean;
};

const UserContext: React.Context<UserContext> = React.createContext({
  isDeepLinkHandled: false,
} as UserContext);

const handleUserLogIn = async (user: firebase.User) => {
  if (!user.isAnonymous) {
    identify(user.uid, true, user.email);
    Analytics.setUserId(user.uid);
  } else {
    identify(user.uid, false);
  }
};

SplashScreen.preventAutoHideAsync();

/**
 * Loads initial assets and initializes Auth,
 * ensuring the app is always in a logged in state
 * (including anon auth)
 */
export const AppInitialization = ({ children }) => {
  const { auth, authError, profile } = useGetAuth();
  const firebase = useFirebase();
  const [hasAssetsLoaded, setHasAssetsLoaded] = useState(false);
  const [previousCredential, setPreviousCredential] = useState<{
    email: string;
    credential: firebase.auth.AuthCredential;
  }>();
  const hasAuthLoaded = isLoaded(auth);
  const isLoggedOut = hasAuthLoaded && isEmpty(auth);

  // States for initializing the app
  const [isDeepLinkHandled, setIsDeepLinkHandled] = useState(false);

  const previousUrl = useRef<string>();

  useEffect(
    function initializeUser() {
      if (isLoggedOut) {
        firebase
          .auth()
          .signInAnonymously()
          .catch(err => {
            Logger.error(err);
          });
      } else if (isLoaded(profile) && isEmpty(profile)) {
        const photoURL = generateDefaultProfilePictureUrl(auth);

        firebase.updateAuth({
          photoURL,
        });

        // Initialize profile with defaults
        const data = {
          isSignedUp: false,
          wantShowAsFriendOfFriends: true,
          photoURL,
        };

        //@ts-expect-error -- bad TS definition
        firebase.updateProfile(data).catch(err => Logger.error(err));

        axios
          .get(`${API_HOST}/addDefaultHabits`)
          .catch(error => Logger.error(error));
      }
    },
    [auth, profile, firebase, isLoggedOut]
  );

  useEffect(
    function handleDeepLink() {
      let mounted = true;
      async function handleLink({ url }) {
        // Deep links fire multiple times for some reason...
        // https://github.com/expo/expo/issues/2128
        if (previousUrl.current === url) {
          Logger.log(`Already handled this URL: ${url}`);
          return;
        }

        previousUrl.current = url;

        const link = Linking.parse(url);
        // console.log(`${url} \n Linked to app with: ${JSON.stringify(link)}`);

        const { queryParams } = link;

        //! snaphabit://signIn is returning path as null, so don't check path right now
        //! since we aren't doing any other deep linking
        const isSignInWithEmailLink = firebase
          .auth()
          .isSignInWithEmailLink(url);

        if (
          isSignInWithEmailLink &&
          (!firebase.auth().currentUser ||
            firebase.auth().currentUser.isAnonymous)
        ) {
          if (mounted) {
            setIsDeepLinkHandled(false);
          }
          await signInWithMagicLink(url, firebase);

          if (Platform.OS === "web") {
            // InviteScreenLayout, JoinChallengeLayout, RoutineOverViewView
            // we redirect back to the page the user logged in from
            window.location.href = queryParams?.webRedirectTo ?? "/";
          }
        }
        if (mounted) {
          setIsDeepLinkHandled(true);
        }
      }

      if (isMobilePlatform) {
        Linking.addEventListener("url", handleLink);

        (async () => {
          const url = await Linking.getInitialURL();
          handleLink({ url });
        })();

        return () => {
          mounted = false;
          Linking.removeEventListener("url", handleLink);
        };
      } else if (isWeb) {
        if (auth.isAnonymous) {
          (async () => {
            const url = await Linking.getInitialURL();
            handleLink({ url });
          })();
        } else {
          setIsDeepLinkHandled(true);
        }
      }
    },
    [auth.isAnonymous, firebase]
  );

  useEffect(
    /**
     * This handles case where user was email sign in and then used Google.
     * Google will override the previous avatar, so we need to restore it.
     */
    function syncUserProfile() {
      if (isSignedUp(profile)) {
        Logger.log("syncUserProfile");
        Logger.log(auth, profile);

        const update: { photoURL?: string; displayName?: string } = {};

        if (profile.photoURL && auth.photoURL !== profile.photoURL) {
          if (isGoogleDefaultImage(auth.photoURL)) {
            // Use our own avatars if it's a Google default photo
            // profile contains our default or the user's updated avatar
            update.photoURL = profile.photoURL;
          } else if (isSnapHabitDefaultImage(profile.photoURL)) {
            // but google isn't default and snaphabit is default, keep the Google one
            // and update in firestore
            firebase
              .updateProfile({
                photoURL: auth.photoURL,
              })
              //@ts-expect-error -- bad TS definition
              .catch(err =>
                Logger.error(err, scope => {
                  scope.addBreadcrumb({
                    message: "syncUserProfile",
                  });

                  return scope;
                })
              );
          }
        }

        if (profile.displayName && auth.displayName !== profile.displayName) {
          update.displayName = profile.displayName;
        }

        if (Object.keys(update).length > 0) {
          firebase.updateAuth(update);
        }
      }
    },
    [firebase, auth, profile]
  );

  useEffect(
    function setUpAuth() {
      const authListener = firebase.auth().onAuthStateChanged(async user => {
        Logger.setUser(user);
        if (user) {
          handleUserLogIn(user);
        } else {
          console.log("No user in userContext - setUpAuth()");
        }
      });

      return function cleanup() {
        authListener();
      };
    },
    [firebase]
  );

  useEffect(
    function storeDeviceInfoAndSetUpNotification() {
      async function getNotificationToken() {
        const { status: existingStatus } = await Permissions.getAsync(
          Permissions.NOTIFICATIONS
        );
        logAppStartupNotificationStatus(existingStatus, profile.isSignedUp);
        let finalStatus = existingStatus;
        // only ask if permissions have not already been determined, because
        // iOS won't necessarily prompt the user a second time.
        // only ask if user is signed up already
        if (existingStatus !== "granted") {
          if (!auth.isAnonymous && profile.isSignedUp) {
            // Android remote notification permissions are granted during the app
            // install, so this will only ask on iOS
            const { status } = await Permissions.askAsync(
              Permissions.NOTIFICATIONS
            );
            finalStatus = status;

            logNotificationPermissionAsk(existingStatus, finalStatus);
          } else {
            return "NOT_SIGNED_UP";
          }
        }

        // Stop here if the user did not grant permissions
        if (finalStatus !== "granted") {
          return "DENIED";
        }

        // Get the token that uniquely identifies this device
        return await Notifications.getExpoPushTokenAsync();
      }

      let mounted = true;

      if (!hasAuthLoaded) {
        return;
      }

      // TODO - VAPID keys for web notifications
      getNotificationToken()
        .then(token => {
          const { installationId } = Constants;
          // https://stackoverflow.com/a/59754809 is only documentation
          // found that shows this is the experience id
          // Using manifest.owner doesn't work because it reads from app.json
          // and doesn't take the value from expo-cli
          // This is really only important for development.
          const experienceId = Constants.manifest.id || "WEB";

          // Always update so that we get the latest build number and sign in dates via lastUpdateDate
          if (mounted && token !== "NOT_SIGNED_UP" && token !== "DENIED") {
            firebase
              .updateProfile(
                {
                  [`tokens.${installationId}`]: {
                    experienceId,
                    platform: Platform.OS,
                    deviceName: Constants.deviceName,
                    token,
                    status: "GRANTED",
                    lastUpdateDate: firebaseSDK.firestore.FieldValue.serverTimestamp(),
                    nativeBuildVersion: Constants.nativeBuildVersion || "",
                    nativeAppVersion: Constants.nativeAppVersion || "",
                    release:
                      (Constants.manifest && Constants.manifest.revisionId) ||
                      "",
                  },
                },
                {
                  /**
                   *  without this, appears to store token as
                   * {
                   *   token.ID: { ... }
                   * }
                   * instead of
                   * {
                   *   token: {
                   *     ID: { ... }
                   *   }
                   * }
                   */
                  useSet: false,
                }
              )
              //@ts-expect-error wrong TS definition
              .then(() => Logger.log("Set token case 1"))
              .catch(err =>
                Logger.error(err, scope => {
                  scope.addBreadcrumb({
                    message: "Set token case 1",
                  });
                  return scope;
                })
              );
          } else if (mounted) {
            firebase
              .updateProfile(
                {
                  [`tokens.${installationId}`]: {
                    experienceId,
                    platform: Platform.OS,
                    status: token,
                    deviceName: Constants.deviceName,
                    lastUpdateDate: firebaseSDK.firestore.FieldValue.serverTimestamp(),
                    nativeBuildVersion: Constants.nativeBuildVersion || "",
                    nativeAppVersion: Constants.nativeAppVersion || "",
                    release:
                      (Constants.manifest && Constants.manifest.revisionId) ||
                      "",
                  },
                },
                {
                  useSet: false,
                }
              )
              //@ts-expect-error wrong TS definition
              .then(() => Logger.log("Set token case 2"))
              .catch(err =>
                Logger.error(err, scope => {
                  scope.addBreadcrumb({
                    message: "Set token case 2",
                  });
                  return scope;
                })
              );
          }
        })
        .catch(err =>
          Logger.error(err, scope => {
            scope.addBreadcrumb({
              message: "Error getting token",
            });
            return scope;
          })
        );

      return () => {
        mounted = false;
      };
    },
    [firebase, auth.isAnonymous, auth.uid, profile.isSignedUp, hasAuthLoaded]
  );

  // Works in conjunction with LoginError to handle
  // account linking
  // crashing right now
  // Apple crash logs say there's an AsyncStorage issue
  // but in dev there's a quick flash of a redux-firestore
  // query which seems to be the problem
  // https://github.com/albertcui/kinetic-app/issues/780
  // useEffect(
  //   function linkCredentials() {
  //     if (
  //       previousCredential &&
  //       auth.isLoaded &&
  //       auth.email === previousCredential.email
  //     ) {
  //       firebase.linkWithCredential(previousCredential.credential);
  //       setPreviousCredential(null);
  //     }
  //   },
  //   [previousCredential, firebase, auth]
  // );

  useEffect(() => {
    loadAssets().then(() => setHasAssetsLoaded(true));
  }, []);

  useEffect(() => {
    if (hasAuthLoaded && hasAssetsLoaded) {
      SplashScreen.hideAsync();
    }
  }, [hasAuthLoaded, hasAssetsLoaded]);

  if (!hasAuthLoaded || !hasAssetsLoaded) {
    return null;
  }

  // dispatching LOGIN_ERROR resets profile and auth
  // so we need to handle this otherwise profile.isSignedUp is undefined
  // and we infinite spinner
  // TODO - handle this better
  // Maybe go to login screen
  if (authError) {
    return (
      <LoginError
        authError={authError}
        setPreviousCredential={setPreviousCredential}
      />
    );
  }

  // TODO -- anon auth does not work if the device is offline. Handle this.
  if (
    !isDeepLinkHandled ||
    isLoggedOut ||
    !isLoaded(profile) ||
    profile.isSignedUp === undefined // wait for default profile to be initialized (the stuff after signInAnonymously)
  ) {
    return <Loading />;
  }

  return children;
};
