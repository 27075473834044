import React, { useState } from "react";
import { StyleSheet, Linking, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { FeedbackModal } from "components/Feedback/FeedbackModal";
import { KButton } from "components";
import { isMobilePlatform } from "utils/helpers";
import { COLORS } from "utils/appStyles";
import { FeedbackSource } from "types/feedback";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
  },
  button: {
    marginHorizontal: 8,
    marginTop: 12,
  },
});

export const SeeAllRoutinesButton = () => {
  const navigation = useNavigation();

  const onPress = () => navigation.navigate("RoutineListScreen");
  return (
    <KButton
      onPress={onPress}
      label="See All"
      icon="arrow-right-circle-outline"
      mode="text"
      color={COLORS.accent}
      style={styles.button}
    />
  );
};

export const SuggestRoutinesButton = ({ setShouldShowModal }) => {
  const onPress = () => {
    isMobilePlatform
      ? setShouldShowModal(true)
      : Linking.openURL(
          "mailto:feedback@snaphabit.app?subject=Suggesting a new Journey"
        );
  };
  return (
    <KButton
      onPress={onPress}
      mode="text"
      color="black"
      label="Suggest"
      style={styles.button}
    />
  );
};

export const RoutineInviteSectionButtons = () => {
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
  return (
    <View style={styles.container}>
      <SeeAllRoutinesButton />
      <SuggestRoutinesButton setShouldShowModal={setShouldShowModal} />
      {shouldShowModal && (
        <FeedbackModal
          setShouldShowModal={setShouldShowModal}
          title="Suggest a new Journey"
          placeholder="What content would you like to see?"
          source={FeedbackSource.ROUTINE}
        />
      )}
    </View>
  );
};
