import React, { useEffect, useState, useCallback, useMemo, memo } from "react";
import { Text, View, StyleSheet } from "react-native";

import { ContentCenteredView, HabitList, KButton, Loading } from "components";

import { useString } from "hooks";
import {
  useGetAuth,
  useGetIsHabitsLoaded,
  useGetHabitsCount,
} from "redux/selectors";
import { deleteHabit } from "utils/habits";
import { logPressFabAddButton, logPressFabAction } from "utils/analytics";
import { useSelector } from "redux/store";
import { useIsFocused } from "@react-navigation/native";
import { FAB } from "react-native-paper";
import { COLORS } from "utils/appStyles";
import { MAX_NUMBER_UNAUTHENTICATED_HABITS } from "utils/constants";

const Fab = memo(
  ({
    scrollPosition,
    isFabOpen,
    fabActions,
    onFabPress,
  }: {
    scrollPosition;
    isFabOpen;
    fabActions;
    onFabPress;
  }) => {
    // Fab appears above the toast. Hide it
    const toastData = useSelector(state => state.toast);
    // isFocused because component remains mounted even if switch tabs
    const isFocused = useIsFocused();
    const isFabVisible = isFocused && !toastData;
    return (
      isFabVisible && (
        <FAB.Group
          visible={isFabVisible}
          open={isFabVisible && isFabOpen}
          icon={isFabOpen ? "plus-outline" : "plus"}
          fabStyle={styles.fabStyle}
          actions={fabActions}
          onStateChange={onFabPress}
          theme={{ colors: { backdrop: "rgba(0,0,0,0.1)" } }}
        />
      )
    );
  }
);

export const HabitListScreen = ({ route, navigation }) => {
  const s = useString("habitListScreen");
  const { auth } = useGetAuth();
  const isLoaded = useGetIsHabitsLoaded();
  const habitsCount = useGetHabitsCount();
  const [isFabOpen, setIsFabOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const shouldDelete = route.params?.shouldDelete ?? false;
  const habitIdToDelete: string = route.params?.habitid ?? null;

  useEffect(() => {
    if (shouldDelete && habitIdToDelete) {
      deleteHabit(habitIdToDelete);
    }
  }, [shouldDelete, habitIdToDelete]);

  const onFabPress = useCallback(() => {
    logPressFabAddButton(habitsCount);
    setIsFabOpen(state => !state);
  }, []);

  const fabActions = useMemo(
    () => [
      {
        icon: "account-plus",
        label: s("friend"),
        onPress: () => {
          logPressFabAction("friend", habitsCount);
          if (auth && auth.isAnonymous) {
            navigation.navigate("LoginScreen", {
              reason: "addFriend",
            });
          } else {
            navigation.navigate("AddFriendScreen");
          }
        },
      },
      {
        icon: "checkbox-marked-outline",
        label: s("habit"),
        onPress: () => {
          logPressFabAction("habit", habitsCount);
          if (
            auth &&
            auth.isAnonymous &&
            habitsCount >= MAX_NUMBER_UNAUTHENTICATED_HABITS
          ) {
            navigation.navigate("LoginScreen", {
              reason: "addHabit",
            });
          } else {
            navigation.navigate("AddHabitScreen");
          }
        },
      },
    ],
    [auth, habitsCount, navigation, s]
  );

  if (!isLoaded || habitsCount === undefined) {
    return <Loading />;
  }

  if (habitsCount === 0) {
    return (
      <ContentCenteredView>
        <Text>{s("nothingHere")}</Text>
        <Text>{s("thePointOfThisApp")}</Text>
        <KButton
          icon="plus-circle"
          label={s("addHabit")}
          onPress={() => navigation.navigate("AddHabitScreen")}
          style={{ margin: 10 }}
        />
      </ContentCenteredView>
    );
  }

  return (
    <View style={styles.container}>
      <HabitList ownHabit setScrollPosition={setScrollPosition} />
      <Fab
        isFabOpen={isFabOpen}
        scrollPosition={scrollPosition}
        onFabPress={onFabPress}
        fabActions={fabActions}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  invites: {
    position: "absolute",
    bottom: 10,
    height: 240,
    zIndex: 1,
    paddingVertical: 8,
    width: "100%",
  },
  fabStyle: {
    backgroundColor: COLORS.logoBlue,
  },
});
