import React, { useLayoutEffect } from "react";
import { View } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import { StackProps } from "layouts/MobileLayout";
import { useGetSpecificHabit, useGetAuth } from "redux/selectors";
import { isAChallenge } from "types/habits";
import { isMobilePlatform } from "utils/helpers";
import { Appbar, useTheme } from "react-native-paper";
import { doShare } from "utils/routines";
import { HabitRoutineView } from "components/Routines/HabitRoutineView";
import { logShareButtonPress } from "utils/analytics";

export const HabitRoutineScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationProp<StackProps, "HabitRoutineScreen">;
  route: RouteProp<StackProps, "HabitRoutineScreen">;
}) => {
  const { habitid, friendid } = route.params;
  const { habit } = useGetSpecificHabit(habitid);
  const { profile } = useGetAuth();
  const theme = useTheme();

  const ownHabit = !friendid;

  const isChallenge = isAChallenge(habit);

  useLayoutEffect(
    function renderHeaderIcon() {
      if (isMobilePlatform && ownHabit) {
        navigation.setOptions({
          headerRight: () => (
            <View style={{ flexDirection: "row" }}>
              <Appbar.Action
                icon="account-plus"
                color={theme.colors.accent}
                onPress={() => {
                  logShareButtonPress("header", "HabitRoutineView");
                  doShare({ ...habit, id: habitid }, profile);
                }}
              />

              {isChallenge ? (
                <Appbar.Action
                  icon="information-outline"
                  color={theme.colors.accent}
                  onPress={() => {
                    navigation.navigate("HabitScreen", {
                      title: habit.title,
                      habitid,
                    });
                  }}
                />
              ) : (
                <Appbar.Action
                  icon="edit"
                  color={theme.colors.accent}
                  onPress={() =>
                    navigation.navigate("AddHabitScreen", {
                      habit,
                      habitid,
                    })
                  }
                />
              )}
            </View>
          ),
        });
      }
    },
    [
      navigation,
      habit,
      habitid,
      theme,
      friendid,
      profile,
      isChallenge,
      ownHabit,
    ]
  );

  return (
    <HabitRoutineView habit={habit} habitid={habitid} friendid={friendid} />
  );
};
