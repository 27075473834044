import { Ionicons } from "@expo/vector-icons";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useState } from "react";
import {
  Text,
  StyleSheet,
  ScrollView,
  View,
  LayoutChangeEvent,
} from "react-native";
import { Modal, Portal } from "react-native-paper";
import { TouchableOpacity } from "react-native-gesture-handler";

import { filterSortFriends } from "utils/friends";
import { StackProps } from "layouts/MobileLayout";
import { truncate } from "utils/strings";
import { HabitFriendList } from "components/EditHabit/HabitFriendList";
import { KAvatar } from "components/UI/KAvatar";
import { Friend } from "types/friends";
import { useNavigation } from "@react-navigation/native";
import { useLayout } from "react-native-web-hooks";
import { useGetAuth } from "redux/selectors";
import { useLayoutContext, Layouts } from "contexts";
import { useHistory } from "utils/react-router";

const MAX_FRIEND_DISPLAY = 9;

const NAME_DISPLAY_LENGTH = 10;

const truncateName = name => {
  //can't display full first and last name
  if (name.length > NAME_DISPLAY_LENGTH) {
    const [firstName, secondName] = name.split(" ");
    const abbreviation = secondName ? " " + secondName.substring(0, 1) : "";

    //show first name + initial if have space, else truncate first name
    return firstName.length + abbreviation.length <= NAME_DISPLAY_LENGTH
      ? `${firstName}${abbreviation ? " " + abbreviation : ""}`
      : truncate(firstName, NAME_DISPLAY_LENGTH);
  } else {
    return name;
  }
};

const SelectedIcon = avatarContainerWidth => () => (
  <Ionicons
    name="ios-checkmark-circle"
    color="green"
    size={avatarContainerWidth * 0.28}
  />
);

const Label = ({ text, width }) => (
  <Text
    style={[styles.displayName, { fontSize: width * 0.26 }]}
    numberOfLines={1}
  >
    {text}
  </Text>
);
const CheckableToggle = ({
  isSelected,
  friendid,
  onToggle,
  avatar,
  email,
  name,
  width,
}) => {
  const displayName = name
    ? truncateName(name)
    : truncate(email, NAME_DISPLAY_LENGTH);

  return (
    <View style={styles.checkableToggleContainer}>
      <TouchableOpacity
        onPress={() => onToggle(friendid, !isSelected)}
        style={styles.checkableToggleContent}
      >
        <KAvatar
          size={width}
          {...{
            source: avatar && { uri: avatar },
            iconName: !avatar && "account",
            iconColor: !avatar && "black",
          }}
          avatarStyle={{
            ...styles.itemShadow,
            ...styles.center,
            ...(isSelected ? styles.selected : {}),
          }}
          Icon={isSelected && SelectedIcon(width)}
          iconContainerStyle={{ bottom: width * -0.1, right: width * -0.1 }}
        />
        <Label text={displayName} width={width * 0.9} />
      </TouchableOpacity>
    </View>
  );
};

const AddFriendButton = ({
  onToggle,
  width,
}: {
  onToggle: (uid: string, val: boolean) => void;
  width: number;
}) => {
  const { auth } = useGetAuth();
  const layout = useLayoutContext();
  const history = useHistory();

  const navigation = useNavigation<StackNavigationProp<StackProps>>();
  return (
    <View style={styles.checkableToggleContainer}>
      <TouchableOpacity
        onPress={() => {
          if (!auth || (auth && auth.isAnonymous)) {
            layout === Layouts.WIDE
              ? history.push("/me")
              : navigation.navigate("LoginScreen", {
                  reason: "addFriend",
                });
          } else {
            layout === Layouts.WIDE
              ? history.push("/me/addFriend")
              : navigation.navigate("AddFriendScreen", {
                  toggleSharing: onToggle,
                });
          }
        }}
        style={styles.checkableToggleContent}
      >
        <View
          style={[
            {
              width,
              height: width,
              borderRadius: width,
            },
            styles.itemShadow,
            styles.addFriendItem,
          ]}
        >
          <Ionicons name="md-person-add" size={width * 0.55} />
        </View>
        <Label text="Add Friend" width={width * 0.9} />
      </TouchableOpacity>
    </View>
  );
};

export const FriendShareSection = ({
  shareCount,
  sharing,
  onToggle,
  friendsArray,
}: {
  shareCount;
  sharing;
  onToggle;
  friendsArray: Friend[];
}) => {
  const [isFriendListVisible, setIsFriendListVisible] = useState(false);
  const sortedFriends = filterSortFriends(friendsArray);
  const { onLayout, width } = useLayout();
  const itemWidth = Math.min((width / 5) * 0.75, 80);

  return (
    <>
      <Text style={[styles.header, { fontSize: width * 0.045 }]}>
        Choose Friends ({shareCount} selected){" "}
      </Text>
      <View style={styles.friendListContainer}>
        <View
          style={styles.friendShareToggleList}
          onLayout={onLayout as (event: LayoutChangeEvent) => void}
        >
          <AddFriendButton
            width={itemWidth}
            onToggle={(key, val) => {
              onToggle(key, val);
            }}
          />
          {sortedFriends.slice(0, MAX_FRIEND_DISPLAY).map(friend => (
            <CheckableToggle
              key={friend.id}
              isSelected={sharing[friend.id]}
              onToggle={onToggle}
              friendid={friend.id}
              avatar={friend.avatar}
              email={friend.email}
              name={friend.name}
              width={itemWidth}
            />
          ))}
        </View>
        {sortedFriends.length > MAX_FRIEND_DISPLAY && (
          <TouchableOpacity
            style={styles.button}
            onPress={() => setIsFriendListVisible(true)}
          >
            <Text>View all friends</Text>
          </TouchableOpacity>
        )}
      </View>
      <Portal>
        <Modal
          visible={isFriendListVisible}
          dismissable
          onDismiss={() => setIsFriendListVisible(false)}
          contentContainerStyle={styles.modalContent}
        >
          <ScrollView>
            <HabitFriendList
              sharing={sharing}
              sortedFriends={sortedFriends}
              onToggle={(key, val) => onToggle(key, val)}
            />
          </ScrollView>
          <TouchableOpacity
            onPress={() => setIsFriendListVisible(false)}
            style={styles.button}
          >
            <Text>Close</Text>
          </TouchableOpacity>
        </Modal>
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
  header: { fontFamily: "OpenSans", opacity: 0.6, marginBottom: 10 },
  friendListContainer: {
    marginTop: 10,
  },
  friendShareToggleList: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  checkableToggleContainer: {
    flexBasis: "20%",
    marginBottom: 20,
  },
  checkableToggleContent: {
    alignItems: "center",
  },
  displayName: {
    fontFamily: "OpenSans",
    marginTop: 10,
  },
  selected: {
    overflow: "hidden",
    borderColor: "green",
    borderWidth: 2,
  },
  itemShadow: {
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: "#CECECE",
    shadowOpacity: 1,
    shadowRadius: 2,
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  addFriendItem: {
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#DDDDDD",
    padding: 8,
    marginTop: 10,
    borderRadius: 4,
    marginBottom: 8,
    alignSelf: "center",
  },
  modalContent: {
    width: "80%",
    height: "80%",
    maxWidth: 400,
    maxHeight: 750,
    alignSelf: "center",
    backgroundColor: "white",
  },
});
