/**
 * Expo Segment analytics doesn't work on web so leaving empty function calls here to prevent errors
 */
import { envIsDevelopment, envIsBeta } from "utils/constants";
import { Habit, HabitRoutine, SaveHabitData } from "types/habits";
import { FeedContent } from "types/feed";
import { MotivationType } from "types/friends";

// declare global {
//   interface Window {
//     analytics: any;
//   }
// }

export const track = (name: string, properties: any) => {
  window.analytics.track(name, properties);
};

export const identify = (userID: string, isSignedUp: boolean, email = "") => {
  window.analytics.identify(userID, {
    environment:
      __DEV__ || envIsDevelopment()
        ? "development"
        : envIsBeta()
        ? "beta"
        : "production",
    isSignedUp,
    email,
  });
};
// export const identifyWithTraits = (userID: string, traits) => {
//   Segment.identifyWithTraits(userID, traits);
// };

export const logScreen = (screenName: string) => {
  window.analytics.page(screenName);
};

/**
 * -------------------------------
 * Day Analytics
 * -------------------------------
 */

export const logUpdateDay = properties => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Habits Analytics
 * -------------------------------
 */

export const logAddHabit = (habit: Habit) => {
  // Intentionally empty
};
export const logEditHabit = (habit: Habit) => {
  // Intentionally empty
};

export const logDeleteHabit = (habitid: string) => {
  // Intentionally empty
};

export const logArchiveHabit = (habitid: string) => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Home Screen Analytics
 * -------------------------------
 */

// Log presses to blue fab add button
export const logPressFabAddButton = (habitsCount: number) => {
  // Intentionally empty
};

// Log presses to fab action button (add friend or add habit)
export const logPressFabAction = (
  action: "friend" | "habit",
  habitsCount: number
) => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Build (Add/Edit) Habit Screen Analytics
 * -------------------------------
 */

// log when emoji is manually picked from modal
export const logPickEmoji = (emoji: string) => {
  // Intentionally empty
};

// log when any informational modal "i" is pressed
// helps determine what habit property people inquire about most
export const logOpenInfoModal = (
  type: "reminder" | "challenge" | "visibility"
) => {
  // Intentionally empty
};

// Log button clicks on habit screen, such as the Save or Cancel (back) button
export const logClickHabitButton = (type: "save" | "cancel") => {
  // Intentionally empty
};

export const logViewVisibilityMenu = () => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Friend Screen Analytics
 * -------------------------------
 */

// Log presses to Add Friend button on headerRight of Friend Screen
export const logTapAddFriendHeader = () => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Reminders Analytics
 * -------------------------------
 */

export const logAddReminder = (time, isChallenge) => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Notifications Analytics
 * -------------------------------
 */

export const logNotificationTap = (
  type:
    | "friend"
    | "chat"
    | "reminder"
    | "reminder_mark_complete"
    | "reminder_add_note"
    | "motivation"
) => {
  // Intentionally empty
};

export const logAppStartupNotificationStatus = (
  status: string,
  isSignedUp: boolean
) => {
  //
};

export const logNotificationPermissionAsk = (
  previousStatus: string,
  finalStatus: string
) => {
  //
};

export const logTapGrantNotificationCard = (action: string) => {
  //
};

/**
 * -------------------------------
 * Group Habits Analytics
 * -------------------------------
 */

export const logJoinGroupHabit = (habitInfo: object, fromScreen: string) => {
  track("group_habit_joined", {
    ...habitInfo,
    fromScreen,
  });
};

// Log join vs dismiss rate from group/routine habit invite cards
export const logPressInviteCardButton = (action: "join" | "dismiss") => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Routine Analytics
 * -------------------------------
 */

export const logStartRoutine = (
  habitData: SaveHabitData,
  routineId: string
) => {
  // Intentionally empty
};

export const logJoinRoutine = (routine: HabitRoutine, fromScreen: string) => {
  // Intentionally empty
};

// Log when user presses 'Complete Day' on a routine to analyze what days user complete more
export const logCompleteRoutineDay = (habit: HabitRoutine, day: number) => {
  // Intentionally empty
};

// Log presses to media carousel vs Markdown link to open a routine content url
export const logTapRoutineContent = (source: "media" | "url", url: string) => {
  // Intentionally empty
};

// Learn More vs Dismiss tap rate for particular routines
export const logTapRoutineInviteCard = (
  action: "learn_more" | "dismiss",
  routineId: string
) => {
  // Intentionally empty
};

// Do people tap other days for routine in day carousel?
export const logSwitchRoutineDay = (index: number, habitid: string) => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Invites and Sharing Analytics
 * -------------------------------
 */

// Track methods people are relying on to invite a friend, along with whether sms is supported
//various ways of inviting friends
//various ways of inviting friends
export const logTryAddFriend = (
  body:
    | { id: string; name: string; email?: undefined }
    | { email: string; name?: undefined }
    | { id: string },
  screen: string,
  friendOfFriendSuggestion = false,
  suggestedContactNotFriendOfFriend = false
) => {
  //keep in sync with addFriendAction
  const canSendRealInvite = !!body.email || !!body.id;
  track("addfriend", {
    canSendRealInvite,
    screen,
    friendOfFriendSuggestion,
    suggestedContactNotFriendOfFriend,
  });
};

export const logFriendInviteSent = () => {
  track("addfriend_invite_sent");
};

//poor mans logging, as think we may have issues here
export const logFriendInviteError = (
  body:
    | { id: string; name: string; email?: undefined }
    | { email: string; name?: undefined }
    | { id: string },
  error
) => {
  track("addfriend_invite_error", {
    body,
    error,
  });
};

//contact row raps
export const logSMSInviteFromContactsScreen = (realInviteSent: boolean) => {
  // Intentionally empty
};

//buttons on contact list screen
export const logAddFriendByEmailPress = () => {
  // Intentionally empty
};

export const logAddFriendByUsernamePress = () => {
  // Intentionally empty
};

export const logAddFriendByShareLinkPress = () => {
  // Intentionally empty;
};

// Track any share click from where is was triggered

// Track any share click from where is was triggered
export const logShareButtonPress = (
  type: "header" | "avatar" | "habit_link_widget" | "profile_link_widget",
  source: string
) => {
  //empty
};

export const logShareRoutineDayFinished = (
  sharedTo: "instagram" | "facebook" | "other",
  routineId: string
) => {
  //
};
/**

/**
 * -------------------------------
 * Profile (Settings) Screen Analytics
 * -------------------------------
 */

export const logReorderHabits = () => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Chat Analytics
 * -------------------------------
 */

// TODO: Not working when calling in KMessageInput
export const logSendChatMessage = (length: number) => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Navigation Analytics
 * -------------------------------
 */

// Log switching between Tab Views in HabitDetailsScreen
export const logToggleHabitTabView = (scene: "graphs" | "notes") => {
  // Intentionally empty
};

// Log switching between Tab Views in RoutineOverviewScreen
export const logToggleRoutineTabView = (scene: "routine" | "details") => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Auth Analytics
 * -------------------------------
 */

// What methods are people using to log in?
export const logAuthLogin = (
  method: "google" | "facebook" | "apple" | "email"
) => {
  track("auth_login_tapped", {
    method,
  });
};

// Log auth flow entering names (along with length of name)
export const logAuthEnterName = (length: number) => {
  // Intentionally empty
};

// Log auth flow sign outs with uids
export const logAuthSignOut = (uid: string) => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Feed Analytics
 * -------------------------------
 */

// Log opens to the feed content
export const logOpenFeedContent = (content: FeedContent) => {
  // Intentionally empty
};

// Log presses to share button for feed content
export const logShareFeedContent = (content: FeedContent) => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Feedback Analytics
 * -------------------------------
 */

export const logShareFeedback = (
  length: number,
  feedback: string,
  source: any
) => {
  // Intentionally empty
};

/**
 * -------------------------------
 * Motivation Analytics
 * -------------------------------
 */

export const logSendMotivation = (
  presses: number,
  type: MotivationType,
  habitid?: string
) => {
  // Intentionally empty since motivation button only on mobile
};
