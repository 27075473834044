import { useActionSheet } from "@expo/react-native-action-sheet";
import { Ionicons, Feather } from "@expo/vector-icons";
import Constants from "expo-constants";
import * as ImagePicker from "expo-image-picker";
import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import * as VideoThumbnails from "expo-video-thumbnails";
import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ActivityIndicator,
  StyleProp,
  ImageStyle,
} from "react-native";

import { Logger } from "utils/Logger";

export const IMAGE_HEIGHT = 70;

const takePhoto = async () => {
  const { status } = await ImagePicker.requestCameraPermissionsAsync();

  if (status !== "granted") {
    return null;
  }

  const result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
  });

  return result.cancelled ? null : result;
};

/**
 * TODO - custom image selection UI
 * https://facebook.github.io/react-native/docs/cameraroll#__docusaurus
 * https://github.com/polycarpou/expo-camera-roll-select
 * https://github.com/hyakt/expo-multiple-media-imagepicker
 */
const selectFromGallery = async () => {
  if (Constants.platform.ios) {
    const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
    if (status !== "granted") {
      //TODO: confirm this works as intended
      return null;
    }
  }
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    quality: 0.5,
    exif: true,
    base64: false, //if need, may need to prepend with "data:image/jpeg;base64," + result.base64 to use
  });

  return result.cancelled ? null : result;
};

export const InputActions = ({
  setSelectedPhoto,
  selectedPhoto,
  inputActionsStyle,
}) => {
  const { showActionSheetWithOptions } = useActionSheet();
  const onOpenActionSheet = async () => {
    const options = ["Camera", "Library", "Cancel"];
    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      async buttonIndex => {
        if (buttonIndex === 0) {
          const fileData = await takePhoto();
          setSelectedPhoto(fileData);
        } else if (buttonIndex === 1) {
          const fileData = await selectFromGallery();
          setSelectedPhoto(fileData);
        }
      }
    );
  };
  return (
    <TouchableOpacity onPress={onOpenActionSheet} style={inputActionsStyle}>
      <Feather
        name="image"
        size={25}
        //TODO: Shift these colors to common component
        color={selectedPhoto ? "#4F8EF7" : "#a9a9a9"}
      />
    </TouchableOpacity>
  );
};

///The InputToolbar expands to the correct height based on  accessoryStyle  prop style in ChatScreen
export const ImageThumbnail = ({
  selectedPhoto,
  setSelectedPhoto,
  isUploading,
  imageStyle,
}: {
  selectedPhoto: ImageInfo;
  setSelectedPhoto: React.Dispatch<ImagePicker.ImagePickerResult>;
  isUploading: boolean;
  imageStyle?: StyleProp<ImageStyle>;
}) => {
  const [uri, setUri] = useState(null);

  useEffect(() => {
    if (selectedPhoto?.type === "video") {
      (async () => {
        try {
          const { uri } = await VideoThumbnails.getThumbnailAsync(
            selectedPhoto.uri,
            {
              quality: 0.25,
            }
          );
          setUri(uri);
        } catch (error) {
          //TODO - handle this better. Should probably have a fallback image
          Logger.error(error);
          setUri(null);
        }
      })();
    } else {
      setUri(selectedPhoto.uri);
    }
  }, [selectedPhoto]);

  if (uri === null) {
    return <></>;
  }

  return (
    <View style={[styles.thumbnail, imageStyle]}>
      {isUploading && (
        <View
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            borderWidth: 1,
            justifyContent: "center",
            backgroundColor: "#333333",
            zIndex: 1,
            opacity: 0.5,
          }}
        >
          <ActivityIndicator color="white" size="large" />
        </View>
      )}
      <Image source={{ uri }} style={[styles.thumbnail, imageStyle]} />
      {!isUploading && (
        <TouchableOpacity
          disabled={isUploading}
          style={{ position: "absolute", zIndex: 1 }}
          onPress={() => setSelectedPhoto(null)}
        >
          <Ionicons
            name="md-close-circle"
            size={25}
            //TODO: Shift these colors to common component
            color="white"
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  imageButton: {},
  thumbnail: {
    height: IMAGE_HEIGHT,
    width: IMAGE_HEIGHT,
  },
});
