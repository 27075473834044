import React, { useState, useEffect } from "react";
import { StyleSheet, Platform } from "react-native";
import Animated, { Easing } from "react-native-reanimated";
import { CARD_WIDTH_PERCENTAGE } from "components/HabitInvites/InviteCarousel";
import { KCard } from "components/UI/KCard";

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
});

/**
 * Animates away the card when isShown is set to false
 */
export const AnimatedInviteCard = ({ isShown, width, children }) => {
  const cardWidth = Math.min(CARD_WIDTH_PERCENTAGE * width, 400);
  const [fadeAnimation] = useState(new Animated.Value(1));
  const [widthAnimation] = useState(
    new Animated.Value(CARD_WIDTH_PERCENTAGE * width)
  );

  useEffect(() => {
    if (!isShown) {
      Animated.timing(fadeAnimation, {
        toValue: 0,
        easing: Easing.linear,
        duration: 400,
      }).start(() => {
        Animated.timing(widthAnimation, {
          toValue: 0,
          easing: Easing.linear,
          duration: 300,
        }).start();
      });
    }
  }, [isShown, fadeAnimation, widthAnimation]);

  return (
    <Animated.View
      style={[
        Platform.select({
          web: {
            width: isShown ? cardWidth : 0,
            bottom: isShown ? 0 : -1000,
          },
          default: {
            width: widthAnimation,
            opacity: fadeAnimation,
          },
        }),
        styles.container,
      ]}
    >
      <KCard
        style={{
          width: cardWidth * 0.95,
          flex: 1,
          marginBottom: 0,
          margin: 4,
        }}
      >
        {children}
      </KCard>
    </Animated.View>
  );
};
