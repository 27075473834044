import { MaterialIcons, Ionicons } from "@expo/vector-icons";
import { createMaterialBottomTabNavigator } from "@react-navigation/material-bottom-tabs";
import { RouteProp } from "@react-navigation/native";
import {
  createStackNavigator,
  HeaderTitle,
  StackNavigationProp,
} from "@react-navigation/stack";
import React, { useLayoutEffect, useEffect } from "react";
import { Platform, View } from "react-native";
import { useTheme, Appbar } from "react-native-paper";
import { Habit, Note, HabitChallenge } from "types/habits";

import { LoginScreen } from "screens/Auth/LoginScreen";
import { ChatListScreen } from "screens/Chats/ChatListScreen";
import { ChatScreen } from "screens/Chats/ChatScreen";
import { ContactsListScreen } from "screens/Contacts/ContactsListScreen";
import { FriendHabitScreen } from "screens/Friends/FriendHabitScreen";
import { FriendListScreen } from "screens/Friends/FriendListScreen";
import { FriendScreen } from "screens/Friends/FriendScreen";
import { FriendSettingsScreen } from "screens/Friends/FriendSettingsScreen";
import { FriendsOfThisFriendScreen } from "screens/Friends/FriendsOfThisFriendScreen";
import { SharedHabitsToggleScreen } from "screens/Friends/SharedHabitsToggleScreen";
import { AddHabitScreen } from "screens/Habits/AddHabitScreen";
import { EditDayNoteScreen } from "screens/Habits/EditDayNoteScreen";
import { HabitChallengeScreen } from "screens/Habits/HabitChallengeScreen";
import { HabitRoutineScreen } from "screens/Habits/HabitRoutineScreen";
import { HabitListScreen } from "screens/Habits/HabitListScreen";
import { HabitScreen } from "screens/Habits/HabitScreen";
import { ArchiveScreen } from "screens/Profile/ArchiveScreen";
import { LanguageScreen } from "screens/Profile/LanguageScreen";
import { HabitOrderingScreen } from "screens/Profile/HabitOrderingScreen";
import { NotificationSettingsScreen } from "screens/Profile/NotificationSettingsScreen";
import { ProfileScreen } from "screens/Profile/ProfileScreen";
import { Friend, FriendStatus } from "types/friends";
import { createSelector } from "@reduxjs/toolkit";
import { useGetAuth } from "redux/selectors";
import { useSelector } from "redux/store";
import { COLORS } from "utils/appStyles";
import { firstName, truncate } from "utils/strings";
import { Logger } from "utils/Logger";
import { RoutineOverviewScreen } from "screens/Routines/RoutineOverviewScreen";
import { RoutineListScreen } from "screens/Routines/RoutineListScreen";
import { ChatThreadScreen } from "screens/Chats/ChatThreadScreen";
import { useChatUnreadCount } from "contexts";

const DEFAULT_STACK_SCREEN_OPTIONS = {
  cardStyle: {
    backgroundColor: "white",
  },
  headerTintColor: COLORS.accent,
  headerTitle: ({ children, ...rest }) => (
    <HeaderTitle {...rest}>{truncate(children as string, 20)}</HeaderTitle>
  ),
};

// Back button not otherwise rendering on Android...
if (Platform.OS === "android") {
  //@ts-ignore
  DEFAULT_STACK_SCREEN_OPTIONS.headerBackImage = ({ tintColor }) => (
    <MaterialIcons
      name="arrow-back"
      color={tintColor}
      size={24}
      style={{ marginHorizontal: Platform.OS === "ios" ? 8 : 0 }}
    />
  );
}

const Tabs = createMaterialBottomTabNavigator();

const EmptyScreen = () => <View />;

const friendsSelector = createSelector(
  state => state?.firestore.ordered.friends,
  friends =>
    friends
      ? friends.filter(friend => friend.status === FriendStatus.Pending).length
      : 0
);

const HomeTabsLayout = ({ theme, auth, friendBadgeCount, chatBadgeCount }) => {
  return (
    <Tabs.Navigator
      initialRouteName="Habits"
      activeColor={theme.colors.accent}
      inactiveColor={theme.colors.disabled}
      barStyle={{ borderTopWidth: 1, borderTopColor: "#eeeeee" }}
      shifting={false}
      labeled={false}
    >
      <Tabs.Screen
        name="Habits"
        component={HabitListScreen}
        options={{
          title: "Habits",
          tabBarIcon: ({ focused, color }) => (
            <Ionicons size={25} color={color} name="md-home" />
          ),
        }}
      />
      <Tabs.Screen
        name="Friends"
        options={{
          tabBarBadge:
            auth && auth.isAnonymous
              ? 1
              : friendBadgeCount > 0
              ? friendBadgeCount
              : false,
          tabBarIcon: ({ color }) => (
            <Ionicons size={25} color={color} name="md-people" />
          ),
        }}
        component={auth && auth.isAnonymous ? EmptyScreen : FriendListScreen}
      />
      <Tabs.Screen
        name="Chat"
        options={{
          title: "Chat",
          tabBarBadge:
            chatBadgeCount > 0 && (chatBadgeCount > 9 ? "9+" : chatBadgeCount),
          tabBarIcon: ({ color }) => (
            <Ionicons size={25} color={color} name="ios-text" />
          ),
        }}
        component={auth && auth.isAnonymous ? EmptyScreen : ChatListScreen}
      />
      <Tabs.Screen
        name="Profile"
        options={{
          tabBarIcon: ({ color }) => (
            <Ionicons size={25} color={color} name="md-settings" />
          ),
          title: "Profile",
        }}
        component={auth && auth.isAnonymous ? EmptyScreen : ProfileScreen}
      />
    </Tabs.Navigator>
  );
};

const HomeTabs = ({
  navigation,
  route,
}: {
  navigation: StackNavigationProp<StackProps, "Home">;
  route: RouteProp<StackProps, "Home"> & {
    state?: { index: number; routes: { name: string }[] };
  };
}) => {
  const theme = useTheme();
  const { auth } = useGetAuth();
  const chatUnreadBadgeCount = useChatUnreadCount();
  const friendBadgeCount = useSelector(friendsSelector);

  Logger.log("Home Tabs re-rendering");
  // Get a name of current screen so that we can set header
  // Header is at the parent stack navigator level
  const routeName = route.state
    ? route.state.routes[route.state.index].name
    : "Habits";

  useLayoutEffect(() => {
    let options = {};
    switch (routeName) {
      case "Habits":
        options = {
          headerShown: false,
        };
        break;
      case "Friends":
        options = {
          title: "Friends",
          headerRight: () => (
            <Appbar.Action
              icon="account-plus"
              color={theme.colors.accent}
              onPress={() => navigation.navigate("AddFriendScreen")}
            />
          ),
        };
        break;
      case "Chat":
        options = {
          title: "Chat",
          headerRight: null,
        };
        break;
      case "Profile":
        options = {
          title: "Profile",
          headerRight: null,
        };
        break;
      default:
        break;
    }
    navigation.setOptions(options);
  }, [navigation, routeName, theme]);

  useEffect(() => {
    if (auth && auth.isAnonymous && routeName !== "Habits") {
      navigation.navigate("LoginScreen", {
        reason: routeName,
      });
    }
  }, [routeName, auth, navigation]);

  return (
    <HomeTabsLayout
      auth={auth}
      theme={theme}
      friendBadgeCount={friendBadgeCount}
      chatBadgeCount={chatUnreadBadgeCount}
    />
  );
};

export type StackProps = {
  Home: undefined;
  Habits: undefined;
  HabitScreen: {
    habitid: string;
    title?: string;
    habit?: Habit; // used to pass to AddHabitScreen when editing
  };
  HabitChallengeScreen: {
    title: string;
    habitid: string;
    friendid?: string;
  };
  RoutineListScreen: undefined;
  RoutineOverviewScreen: {
    routineId: string;
    profileView?: boolean;
  };
  HabitRoutineScreen: {
    habitid: string;
    title: string;
    friendid?: string;
  };
  AddHabitScreen: {
    sharing?: {
      [friendid: string]: boolean;
    };
    habit?: Habit;
    habitid?: string;
    habitChallenge?: HabitChallenge;
    habitChallengeId?: string;
    routineId?: string;
  };
  EditDayNoteScreen: {
    dayid: string;
    habitid: string;
    friendid?: string;
    date: string;
    editable?: boolean;
    ownHabit?: boolean;
    note?: Note;
    title?: string;
  };
  FriendListScreen: {
    shouldDelete?: boolean;
    friend?: Friend;
  };
  AddFriendScreen: {
    toggleSharing?: (uid: string, key: boolean) => void;
    challengeHabitId?: string;
    addHabitFlow?: boolean;
  };
  FriendsOfThisFriendScreen: {
    title: string;
    friendid: string;
  };
  FriendScreen: {
    title: string;
    friendid: string;
  };
  FriendHabitScreen: {
    habitid: string;
    friendid: string;
    title?: string;
  };
  FriendSettingsScreen: {
    title: string;
    friendid: string;
  };
  SharedHabitsToggleScreen: {
    title: string;
    friendid: string;
  };
  ChatScreen: {
    title: string;
    friendid?: string;
    id?: string;
  };
  ChatThreadScreen: {
    thread: any;
    title: string;
    channelId?: string;
  };
  ArchiveScreen: undefined;
  LanguageScreen: undefined;
  HabitOrderingScreen: undefined;
  NotificationSettingsScreen: undefined;
  LoginScreen: {
    reason?: string;
  };
};

const Stack = createStackNavigator<StackProps>();

export type MainNavigation = StackNavigationProp<StackProps>;

export const MobileLayout = () => {
  const theme = useTheme();

  return (
    <Stack.Navigator screenOptions={DEFAULT_STACK_SCREEN_OPTIONS}>
      <Stack.Screen name="Home" component={HomeTabs} />
      <Stack.Screen name="HabitScreen" component={HabitScreen} />
      <Stack.Screen
        name="HabitChallengeScreen"
        component={HabitChallengeScreen}
        options={({ route }) => ({
          title: route.params?.title ?? "",
        })}
      />
      <Stack.Screen
        name="RoutineOverviewScreen"
        component={RoutineOverviewScreen}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="RoutineListScreen"
        component={RoutineListScreen}
        options={{ title: "All Journeys" }}
      />
      <Stack.Screen
        name="HabitRoutineScreen"
        component={HabitRoutineScreen}
        options={({ route }) => ({
          title: route.params?.title ?? "",
        })}
      />
      <Stack.Screen name="AddHabitScreen" component={AddHabitScreen} />
      <Stack.Screen
        name="EditDayNoteScreen"
        component={EditDayNoteScreen}
        options={({ route }) => {
          const moment = require("moment");
          return {
            title: `Note for ${moment(route.params.date, "MM-DD-YYYY").format(
              "MMMM D"
            )}`,
          };
        }}
      />
      <Stack.Screen
        name="ArchiveScreen"
        component={ArchiveScreen}
        options={{ title: "Habit Archive" }}
      />
      <Stack.Screen
        name="LanguageScreen"
        component={LanguageScreen}
        options={{ title: "🌐" }}
      />
      <Stack.Screen
        name="HabitOrderingScreen"
        component={HabitOrderingScreen}
        options={{ title: "Habits" }}
      />
      <Stack.Screen
        name="ChatScreen"
        component={ChatScreen}
        options={({ navigation, route }) => ({
          title: route.params?.title ?? "Chat",
          headerRight: () => (
            <Appbar.Action
              icon="information-outline"
              color={theme.colors.accent}
              onPress={() =>
                navigation.navigate("FriendScreen", {
                  friendid: route.params?.friendid,
                  title: route.params?.title,
                })
              }
            />
          ),
        })}
      />
      <Stack.Screen
        name="ChatThreadScreen"
        component={ChatThreadScreen}
        options={({ route }) => ({ title: route.params?.title ?? "Thread" })}
      />
      <Stack.Screen
        name="AddFriendScreen"
        component={ContactsListScreen}
        options={{ title: "Add Friend" }}
      />
      <Stack.Screen
        name="FriendsOfThisFriendScreen"
        component={FriendsOfThisFriendScreen}
        options={({ route }) => ({
          title: `${firstName(route.params.title)}'s Friends`,
        })}
      />
      <Stack.Screen
        name="FriendScreen"
        component={FriendScreen}
        options={({ navigation, route }) => ({
          title: route.params.title,
          headerRight: () => (
            <Appbar.Action
              icon="settings"
              color={theme.colors.accent}
              onPress={() =>
                navigation.navigate("FriendSettingsScreen", {
                  friendid: route.params.friendid,
                  title: route.params.title,
                })
              }
            />
          ),
        })}
      />
      <Stack.Screen
        name="FriendHabitScreen"
        component={FriendHabitScreen}
        options={({ route }) => ({
          title: route.params?.title,
        })}
      />
      <Stack.Screen
        name="FriendSettingsScreen"
        component={FriendSettingsScreen}
        options={({ route }) => ({
          title: route.params.title,
        })}
      />
      {/* //TODO: Consider moving shared habits directly into the settings screen
        small issue when have 10-15 habits
     */}
      <Stack.Screen
        name="SharedHabitsToggleScreen"
        component={SharedHabitsToggleScreen}
        options={({ route }) => ({
          title: `Shared with ${route.params.title}`,
        })}
      />
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{ headerShown: false, gestureEnabled: false }}
      />
      <Stack.Screen
        name="NotificationSettingsScreen"
        component={NotificationSettingsScreen}
        options={{ title: "Notifications" }}
      />
    </Stack.Navigator>
  );
};
