import React, { useMemo, useState, useEffect, useCallback, memo } from "react";
import { ViewStyle } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { useSelector } from "redux/store";
import { createSelector } from "@reduxjs/toolkit";
import {
  Habit,
  isHabitChallenge,
  HabitChallenge,
  isHabitChallengeParticipant,
  isHabitRoutine,
} from "types/habits";
import { FriendStatus } from "types/friends";
import { useGetAuth, useGetHabits, useGetFriends } from "redux/selectors";
import { ASYNC_STORAGE_INVITES_KEY } from "utils/constants";
import { ChallengeInviteCard } from "components/Challenges/ChallengeInviteCard";
import { useString } from "hooks";
import { SectionTitle, SectionSubtitle } from "components";
import { InviteSection } from "components/HabitInvites/InviteSection";

const habitSelector = createSelector(
  state => state.firestore?.ordered.friendHabits,
  habits =>
    habits?.filter((h: Habit) => isHabitChallenge(h) && !h.isArchived) ?? []
);

type ChallengeInviteCardData = {
  habitid: string;
  owner: string;
  isShown: boolean;
};

const keyExtractor = item => item.habitid;

const Title = ({ numDisplayedHabits }) => {
  const s = useString("challenge");
  return (
    <>
      <SectionTitle>{s("invitesTitle", numDisplayedHabits)}</SectionTitle>
      <SectionSubtitle>{s("invitesSubtitle")}</SectionSubtitle>
    </>
  );
};

const ChallengeInviteCarousel = ({
  style,
  routineId,
  renderTitle,
}: {
  style?: ViewStyle;
  routineId?: string; // if filtered to a specific routine
  renderTitle: (num: number) => JSX.Element;
}) => {
  const { auth } = useGetAuth();
  /*
    We don't filter out challenges this user is already in
    because if the user joins, we'll reprocess the list
    and the animation won't run!
  */
  const friendChallenges: HabitChallenge[] = useSelector(
    habitSelector,
    (left, right) => left.length === right.length
  );

  const [isInitialized, setIsInitialized] = useState(false);
  const [eligibleHabits, setEligibleHabits] = useState<
    ChallengeInviteCardData[]
  >([]);

  const habits = useGetHabits();

  const { friends } = useGetFriends();

  // Filter friend challenges, omitting
  // a. challenges user is already in
  // b. challenges user has declined (stored in AsyncStorage)
  useEffect(
    function initializeCarouselData() {
      let mounted = true;
      const keys = friendChallenges.map(
        h => `${ASYNC_STORAGE_INVITES_KEY}:${h.id}`
      );

      AsyncStorage.multiGet(keys).then(res => {
        const set = new Set();
        for (let i = 0; i < res.length; i++) {
          const [k, v] = res[i];
          if (v) {
            set.add(k.replace(`${ASYNC_STORAGE_INVITES_KEY}:`, ""));
          }
        }

        // store in a Set for constant-time search
        const acceptedFriendIds = new Set();
        friends.forEach(f => {
          if (
            f.status === FriendStatus.Accepted ||
            f.status === FriendStatus.AcceptedRequestor
          ) {
            acceptedFriendIds.add(f.id);
          }
        });
        const challengesOfAcceptedFriends = friendChallenges.filter(h => {
          return acceptedFriendIds.has(h.uid);
        });

        const notDeclined = challengesOfAcceptedFriends
          .filter(
            h =>
              !set.has(h.id) &&
              !Object.keys(h.challengers).find(u => u === auth.uid) &&
              (routineId
                ? isHabitRoutine(h) && h.routineId === routineId
                : true)
          )
          .map(h => ({
            habitid: h.id,
            owner: h.uid,
            isShown: true,
          }));

        if (mounted) {
          setEligibleHabits(notDeclined);
          setIsInitialized(true);
        }
      });

      return () => {
        mounted = false;
      };
    },
    [auth.uid, friendChallenges, friends]
  );

  const displayedHabits = useMemo(() => eligibleHabits.filter(h => h.isShown), [
    eligibleHabits,
  ]);

  const hideItem = useCallback(
    habitid =>
      setTimeout(
        () =>
          setEligibleHabits(eligible => {
            const ret = [...eligible];
            const thisItem = ret.find(h => h.habitid === habitid);
            if (thisItem) {
              thisItem.isShown = false;
            }

            return ret;
          }),
        300
      ),
    []
  );

  // When user habits change, check if the user joined any of the
  // challenges in the invite list. If so, set `isShown` = false
  // so that the habit disappears from carousel.
  useEffect(
    function updateCarouselOnChallengeJoin() {
      const timeouts = [];
      const set = new Set(displayedHabits.map(h => h.habitid));
      for (let i = 0; i < habits.length; i++) {
        const habit = habits[i];
        if (
          isHabitChallengeParticipant(habit) &&
          set.has(habit.challengeHabitId)
        ) {
          timeouts.push(hideItem(habit.challengeHabitId));
        }
      }

      return () => {
        timeouts.forEach(t => clearTimeout(t));
      };
    },
    [displayedHabits, habits, hideItem]
  );

  const renderItem = useCallback(
    ({ item }: { item: ChallengeInviteCardData }) => (
      <ChallengeInviteCard
        habitid={item.habitid}
        owner={item.owner}
        isShown={item.isShown}
        hideItem={hideItem}
      />
    ),
    [hideItem]
  );

  if (!isInitialized) {
    return null;
  }

  return (
    <InviteSection
      style={style}
      title={renderTitle(displayedHabits.length)}
      data={eligibleHabits}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
    />
  );
};

export const HabitListChallengeInvites = memo(
  ({ style }: { style?: ViewStyle }) => (
    <ChallengeInviteCarousel
      style={style}
      renderTitle={num => <Title numDisplayedHabits={num} />}
    />
  )
);

export const ChallengeInvites = memo(ChallengeInviteCarousel);
