import Constants from "expo-constants";
import { Platform } from "react-native";
import moment from "moment";

import { isMobilePlatform, isWeb } from "./helpers";

export const APP_ENV: string =
  __DEV__ ||
  (isMobilePlatform && Constants.manifest.releaseChannel === undefined)
    ? "development"
    : Platform.OS === "web"
    ? Constants.manifest.extra.APP_ENV
    : Constants.manifest.releaseChannel;

export const envIsDevelopment = () => APP_ENV.startsWith("development");
export const envIsBeta = () => APP_ENV.startsWith("beta");
export const envIsProduction = () => APP_ENV.startsWith("production");

export const LOG_LEVEL = "WARNING"; // if need render logging, replace with __DEV__ ? "DEBUG" : "WARNING";

export const APP_OWNERSHIP = Constants.appOwnership;

export const DISPLAY_VERSION =
  (Constants.manifest.revisionId ? Constants.manifest.revisionId : "") +
  (Constants.nativeBuildVersion ? `(${Constants.nativeBuildVersion})` : "");

export const API_HOST =
  __DEV__ && Platform.OS === "web"
    ? "http://localhost:3000" // Firebase Auth only works for localhost as the domain
    : __DEV__ && envIsDevelopment()
    ? `http://${Constants.manifest.debuggerHost.split(`:`).shift()}:3000` // Same port as backend
    : envIsDevelopment() // stand alone development build
    ? "https://dev.api.snaphabit.app"
    : envIsBeta()
    ? "https://beta.api.snaphabit.app"
    : envIsProduction()
    ? "https://api.snaphabit.app"
    : "UNKNOWN ENVIRONMENT";

export const WEB_HOST_URL =
  __DEV__ && envIsDevelopment()
    ? "http://localhost:19006"
    : envIsDevelopment()
    ? "https://snaphabit.vercel.app"
    : envIsBeta()
    ? "https://beta.app.snaphabit.app"
    : "https://app.snaphabit.app";

if (isWeb) {
  console.log("NODE_ENV is", process.env.NODE_ENV);
  console.log("APP_ENV is", APP_ENV);
  console.log("API_HOST is", API_HOST);
}

export const FIREBASE_CONFIG = envIsDevelopment()
  ? {
      apiKey: "AIzaSyDQKGX8IjNxYUAS1D-NOgElr1bGiPgp1sA",
      authDomain: "kinetic-development.firebaseapp.com",
      projectId: "kinetic-development",
      storageBucket: "kinetic-development.appspot.com",
      appId: "1:546280042180:web:8e0c959023ca9e44af6391",
      measurementId: "G-KZKFPPXS1V",
    }
  : envIsBeta() || envIsProduction()
  ? {
      apiKey: "AIzaSyDA0xkdhp-L2wBZ-rAd2RIVEyibiPstQVM",
      authDomain: "kinetic-staging.firebaseapp.com",
      projectId: "kinetic-staging",
      storageBucket: "kinetic-staging.appspot.com",
      appId: "1:457197320616:web:55cdb8db7da29aeaa3c59d",
      measurementId: "G-14JGZTGCQ5",
    }
  : {};

// Instructions for generating https://docs.expo.io/versions/latest/sdk/google/#using-it-inside-of-the-expo-app
//! MAKE SURE TO SET UP https://console.firebase.google.com/u/0/project/[project]/authentication/users
export const GOOGLE_AUTH_CLIENT_IDS = envIsDevelopment()
  ? {
      androidClientId:
        "546280042180-6ci7kvha4mf942hnffisms75smi23mrq.apps.googleusercontent.com",
      iosClientId:
        "546280042180-4f6uj0iaiot16jo9ntuusu1l3ggkqm9h.apps.googleusercontent.com",
      iosStandaloneAppClientId:
        "546280042180-35024lvpnshs1igsp76685nkkk3m4gh3.apps.googleusercontent.com",
      clientId:
        "546280042180-dga0f5qh866a0sld6l2i6s3kutsks4k3.apps.googleusercontent.com",
    }
  : envIsBeta()
  ? {
      // Using the same Expo client Ids between Production and Beta since
      // they both point to as the bundle identifier host.exp.exponent
      androidClientId:
        "457197320616-p313ivr058higfo59hr2hlnj2mntiist.apps.googleusercontent.com",
      iosClientId:
        "457197320616-lo6o06bfquldt313h3qdalpk27tscjq5.apps.googleusercontent.com",
      iosStandaloneAppClientId:
        "457197320616-klvnsudf43tpp4v287hiphskjo7tpdi9.apps.googleusercontent.com",
      androidStandaloneAppClientId:
        "457197320616-b5ejgi3rv9netsr30p7l7t0tug51emh6.apps.googleusercontent.com",
      clientId:
        "457197320616-js3a96t1l2edo82ssv7icqed8t7jmv25.apps.googleusercontent.com",
    }
  : envIsProduction()
  ? {
      androidClientId:
        "457197320616-p313ivr058higfo59hr2hlnj2mntiist.apps.googleusercontent.com",
      iosClientId:
        "457197320616-lo6o06bfquldt313h3qdalpk27tscjq5.apps.googleusercontent.com",
      iosStandaloneAppClientId:
        "457197320616-9k6tof4ot9h8gqnv949vautaqokbirof.apps.googleusercontent.com",
      androidStandaloneAppClientId:
        "457197320616-jkne6t5ni046mm5sha9aui7vb8cpf0sg.apps.googleusercontent.com",
      clientId:
        "457197320616-js3a96t1l2edo82ssv7icqed8t7jmv25.apps.googleusercontent.com",
    }
  : {};

export const FULLSTORY_ID = "WSZM0";
export const FACEBOOK_ID = envIsDevelopment()
  ? "2887280134838763"
  : envIsBeta() || envIsProduction()
  ? "726435051441300"
  : "";

export const SENDBIRD_APP_ID = envIsDevelopment()
  ? "A606E4B8-991D-4438-AB80-0E5273565CB8"
  : envIsBeta() || envIsProduction()
  ? "443E5A44-1EBA-49D4-832C-EE44BC2EEBEF"
  : "";

export const STREAM_KEY = envIsDevelopment()
  ? "w92bnnp6wcay"
  : envIsBeta() || envIsProduction()
  ? "6g92ee79nkw5"
  : "";

export const SENTRY_DNS =
  "https://a1982cbf551949b5b63c0a5b63f3b0b6@sentry.io/1877693";

export const SIGN_IN_URL = "/signIn";

export const ASYNC_STORAGE_EMAIL_KEY = "@Habit:unverifiedEmail";

export const ASYNC_STORAGE_INVITES_KEY = "@Habit:challengeInvites";

export const ASYNC_STORAGE_APP_OPENS_KEY = "@Habit:appOpens";

export const ASYNC_STORAGE_CARD_VIEWS_KEY = "@Habit:cardViews";

export const ASYNC_STORAGE_RATING_PROMPTED_KEY = "@Habit:ratingPrompted";

export const ASYNC_STORAGE_GRANT_NOTIFICATION_CARD_KEY =
  "@Habit:notificatonCard";

//allow someone to add one habit in addition to default
export const MAX_NUMBER_UNAUTHENTICATED_HABITS = 3;

export const HABIT_LAST_UPDATED_THRESHOLD = moment()
  .subtract(45, "days")
  .valueOf(); // milliseconds

export const ALLOWED_MESSAGE_ACTIONS = ["reactions", "reply"];
