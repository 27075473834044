import React, { useState } from "react";
import { useString } from "hooks";
import { isAChallenge } from "types/habits";
import { RoutineView, HabitDetailsView, KTabBar } from "components";
import { HabitChallengeView } from "components/Challenges/HabitChallengeView";
import { TabView } from "react-native-tab-view";
import { useDimensionsContext } from "contexts";
import { logToggleRoutineTabView } from "utils/analytics";

export const HabitRoutineView = ({ habit, habitid, friendid }) => {
  const [index, setIndex] = useState(0);
  const { width } = useDimensionsContext();
  const s = useString("routine");
  const titleRoutine = s("tabRoutine");
  const titleDetails = s("tabDetails");
  const [routes] = useState([
    { key: "routine", title: titleRoutine },
    { key: "details", title: titleDetails },
  ]);

  const isChallenge = isAChallenge(habit);
  const ownHabit = !friendid;

  const renderScene = ({ route }) => {
    switch (route.key) {
      case "routine":
        return <RoutineView habitid={habitid} friendid={friendid} />;
      case "details":
        if (isChallenge) {
          return <HabitChallengeView habitid={habitid} />;
        }
        return (
          <HabitDetailsView
            habitid={habitid}
            habit={habit}
            ownHabit={ownHabit}
          />
        );
      default:
        return null;
    }
  };

  return (
    <TabView
      renderTabBar={props => <KTabBar {...props} />}
      navigationState={{ index, routes }}
      onIndexChange={index => {
        logToggleRoutineTabView(index === 0 ? "routine" : "details");
        setIndex(index);
      }}
      renderScene={renderScene}
      initialLayout={{ width }}
      style={{ flex: 1 }}
      tabBarPosition="top"
      lazy
    />
  );
};
