import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useSelector as useUntypedSelector,
} from "react-redux";
import {
  firebaseReducer,
  FirebaseReducer,
  FirestoreReducer,
} from "react-redux-firebase";
import { firestoreReducer, actionTypes } from "redux-firestore";
import { createEpicMiddleware, combineEpics } from "redux-observable";
import { filter, map } from "rxjs/operators";

import {
  notesSliceReducer,
  setNotesFromListener,
  setSingleNote,
  sharedDaysSliceReducer,
  toastSliceReducer,
  userDaysSliceReducer,
  setDays,
  setSingleDay,
} from "redux/slices";
// TODO - merge userDaysReducer with sharedDaysReducer -- one place to look up instead of two!
import { Day } from "types/habits";
import { FirestoreUserData } from "types/users";

const DOCUMENT_ADDED = `@@reduxFirestore/DOCUMENT_ADDED`;
const DOCUMENT_MODIFIED = `@@reduxFirestore/DOCUMENT_MODIFIED`;

// TODO - make these typings better
const rootEpic = combineEpics<
  {
    type:
      | typeof actionTypes.LISTENER_RESPONSE
      | typeof DOCUMENT_ADDED
      | typeof DOCUMENT_MODIFIED;
  },
  | ReturnType<typeof setDays>
  | ReturnType<typeof setSingleDay>
  | ReturnType<typeof setSingleNote>
  | ReturnType<typeof setNotesFromListener>,
  RootState
>(
  action$ =>
    action$
      .ofType<{
        type: typeof actionTypes.LISTENER_RESPONSE;
        meta: {
          storeAs: string;
        };
        payload;
      }>(actionTypes.LISTENER_RESPONSE)
      .pipe(
        filter(action => action?.meta?.storeAs === "days"),
        map(action => setDays(action.payload))
      ),
  // Reduce store updates due to DOCUMENT_MODIFIED firing twice
  // for local and remote
  (action$, store$) =>
    action$
      .ofType<{
        type: typeof DOCUMENT_ADDED | typeof DOCUMENT_MODIFIED;
        meta: {
          storeAs: string;
          doc: string;
        };
        payload;
      }>(DOCUMENT_ADDED, DOCUMENT_MODIFIED)
      .pipe(
        filter(action => {
          if (action?.meta?.storeAs === "days" && action?.payload?.data) {
            const currentDay = action.payload.data as Day;
            const prevDay = store$.value.userDays?.[currentDay.habitid]?.[
              currentDay.date
            ] as Day;
            if (prevDay?.status !== currentDay?.status) {
              return true;
            }
          }
          return false;
        }),
        map(action =>
          setSingleDay({
            id: action.meta.doc,
            ...action.payload.data,
          })
        )
      ),
  action$ =>
    action$
      .ofType<{
        type: typeof actionTypes.LISTENER_RESPONSE;
        meta: {
          storeAs: string;
        };
        payload;
      }>(actionTypes.LISTENER_RESPONSE)
      .pipe(
        filter(action => action?.meta?.storeAs === "notes"),
        map(action => setNotesFromListener(action.payload))
      ),
  action$ =>
    action$
      .ofType<{
        type: typeof DOCUMENT_ADDED | typeof DOCUMENT_MODIFIED;
        meta: {
          storeAs: string;
          doc: string;
        };
        payload;
      }>(DOCUMENT_ADDED, DOCUMENT_MODIFIED)
      .pipe(
        filter(action => action?.meta?.storeAs === "notes"),
        map(action =>
          setSingleNote({
            id: action.meta.doc,
            ...action.payload.data,
          })
        )
      )
);

const epicMiddleware = createEpicMiddleware<
  {
    type:
      | typeof actionTypes.LISTENER_RESPONSE
      | typeof DOCUMENT_ADDED
      | typeof DOCUMENT_MODIFIED;
  },
  {
    type: typeof actionTypes.LISTENER_RESPONSE;
  },
  RootState
>();

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  toast: toastSliceReducer,
  sharedDays: sharedDaysSliceReducer,
  notes: notesSliceReducer,
  userDays: userDaysSliceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      // Firebase timestamps are un-serializable
      serializableCheck: false,
      // State is too large
      immutableCheck: false,
    }),
    epicMiddleware,
  ],
});

epicMiddleware.run(rootEpic);

export const useSelector: TypedUseSelectorHook<RootState> = useUntypedSelector;

export type RootState = ReturnType<typeof rootReducer> & {
  firebase: FirebaseReducer.Reducer<FirestoreUserData>;
  firestore: FirestoreReducer.Reducer;
};
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
