import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import * as Contacts from "expo-contacts";
import React, {
  useEffect,
  useState,
  useReducer,
  useMemo,
  useLayoutEffect,
} from "react";
import AsyncStorage from "@react-native-community/async-storage";
import { Button, View } from "react-native";
import { Loading } from "components";

import { useAddFriend } from "hooks/friends/useAddFriend";
import { useGetFriends, useGetAuth } from "redux/selectors";
import {
  FriendOfFriends,
  FriendsOfFriendsResponse,
  AnnotatedContact,
} from "types/friends";
import { Logger } from "utils/Logger";
import axios from "utils/axios";
import { API_HOST } from "utils/constants";
import { filterContacts } from "utils/contacts";
import { StackProps } from "layouts/MobileLayout";
import { fetchReducer } from "utils/reducers";
import { isMobilePlatform } from "utils/helpers";
import { SharingHelper } from "utils/sharing";
import { AddFriendView } from "components/Friends/AddFriendView";
import { logSMSInviteFromContactsScreen } from "utils/analytics";
import { useLayoutContext, Layouts } from "contexts";
import { useTheme } from "react-native-paper";

export const ContactsListScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationProp<StackProps, "AddFriendScreen">;
  route: RouteProp<StackProps, "AddFriendScreen">;
}) => {
  const theme = useTheme();
  const layout = useLayoutContext();
  useLayoutEffect(() => {
    if (
      layout === Layouts.MOBILE &&
      route.params &&
      route.params.addHabitFlow
    ) {
      navigation.setOptions({
        headerRight: () => (
          <View style={{ marginRight: 6 }}>
            <Button
              onPress={() => navigation.navigate("Habits")}
              title="Skip"
              color={theme.colors.accent}
            />
          </View>
        ),
      });
    }
  }, [route.params, navigation, layout, theme.colors.accent]);

  const CACHE_CHECK = useMemo(() => Date.now() - 60 * 60 * 1000, []); // 1 hour
  const { auth, profile } = useGetAuth();
  const [contactsPermission, setContactsPermission] = useState<
    Contacts.PermissionStatus
  >(Contacts.PermissionStatus.UNDETERMINED);
  const [addedInCurrentSession, setAddedInCurrentSession] = useState<
    Set<string>
  >(new Set());
  const [contacts, setContacts] = useState<AnnotatedContact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<AnnotatedContact[]>(
    []
  );
  const [friendsOfFriends, setFriendsOfFriends] = useState<FriendOfFriends[]>(
    []
  );

  const [sharingHelper, setSharingHelper] = useState(() =>
    SharingHelper(profile)
  );

  useEffect(() => {
    setSharingHelper(SharingHelper(profile));
  }, [profile]);

  const [smsSupported, setSMSSupported] = useState(false);
  const [isLoadingContacts, setIsLoadingContacts] = useState(true);
  const [isLoadingFoF, setIsLoadingFoF] = useState(true);
  const { friends } = useGetFriends();
  const [friendEmails, setFriendEmails] = useState<Set<string>>(new Set());
  const [fetchState, dispatch] = useReducer(fetchReducer, {});
  const addFriend = useAddFriend(navigation, route, dispatch);

  // Tries to invite by both email and phone number
  const addFriendAction = (body, payload) => {
    const canSendRealInvite = !!body.email || !!body.id;
    if (canSendRealInvite) {
      addFriend(body, payload);
    }

    if (smsSupported && !!body.number) {
      sharingHelper.doSMSInvite(body.number);
      logSMSInviteFromContactsScreen(canSendRealInvite);
    }

    const newSet = new Set(addedInCurrentSession);
    newSet.add(payload.id);
    setAddedInCurrentSession(newSet);
  };

  useEffect(() => {
    sharingHelper.isSMSAvailableAsync().then(res => setSMSSupported(res));
  }, [sharingHelper]);

  // Re-filter illuminated contacts, when they change, or when filtering
  // criteria changes (i.e. smsSupported async call resolves)
  useEffect(
    function doFilterContacts() {
      if (!contacts || !contacts.length) {
        setFilteredContacts([]);
        return;
      }

      const filtered = filterContacts(contacts, smsSupported);

      const myEmail = auth?.email?.toLowerCase();
      // const myPhone = user.phoneNumber;

      const withOutMe = myEmail
        ? filtered.filter(
            c =>
              !(
                c.emails &&
                c.emails.some(e => e.email.toLowerCase() === myEmail)
              )

            //   &&
            // (!myPhone ||
            //   !c.phoneNumbers ||
            //   !c.phoneNumbers.some(p => p.phoneNumber === myPhone))
          )
        : filtered;

      // Filter out friends who have already been invited
      // except for friends who have been added in this session
      const furthermore = withOutMe.filter(
        ({ id, emails }) =>
          addedInCurrentSession.has(id) ||
          !(
            emails &&
            emails.length &&
            emails.every(({ email }) => friendEmails.has(email))
          )
      );
      setFilteredContacts(furthermore);
    },
    [addedInCurrentSession, contacts, friendEmails, smsSupported, auth]
  );

  function wrapSetContacts(c) {
    setContacts(c);
    setIsLoadingContacts(false);
  }

  // Calculate Contacts (filteredContacts comes as second step).
  useEffect(
    function doGetContacts() {
      // `return`s nothing - uses of return below are convenience to make sure
      // (A) wrapSetContacts() is called, and to (B) short circuit execution.
      async function getContactsAsync() {
        // No contacts, if not phone...
        if (!isMobilePlatform) {
          return wrapSetContacts([]);
        }

        // If no permissions currently, can't request contacts.
        if (contactsPermission !== "granted") {
          return wrapSetContacts([]);
        }

        // Contacts is not just the phone's contacts, but requires "illumination"
        // from the server, to hydrate `knownUserEmail`.  Avoiding server round trip
        // is the benefit of the AsyncStorage cache.
        // TODO: make the server call earlier (on app start?) or make the server call
        // faster so that we can show server response on initial page view
        try {
          const [lastSyncedTime, syncedData] = await AsyncStorage.multiGet([
            "CONTACTS_SYNCED_TIME",
            "CONTACTS_JSON",
          ]);

          if (
            lastSyncedTime[1] !== null &&
            parseInt(lastSyncedTime[1], 10) > CACHE_CHECK
          ) {
            Logger.log("Found cached contacts data");
            return wrapSetContacts(JSON.parse(syncedData[1]));
          }

          Logger.log("No cached data, requesting contacts");

          // Step 1 - get device contacts
          const { data } = await Contacts.getContactsAsync({
            fields: [
              Contacts.Fields.Emails,
              Contacts.Fields.PhoneNumbers,
              Contacts.Fields.Name,
              Contacts.Fields.Image,
            ],
          });

          // We can early out if nothing to illuminate.
          if (!data || !data.length) {
            return wrapSetContacts([]);
          }

          wrapSetContacts(data);

          // Step 2 - Upload contacts, to get 'knownUserEmail' and save to cache for next
          // session
          //! Do not update the current state otherwise the UI
          //! re-renders when the response comes back, creating
          //! a very janky experience
          axios
            .post(`${API_HOST}/contacts`, data)
            .then(res => {
              const contacts = res.data;

              AsyncStorage.multiSet([
                ["CONTACTS_SYNCED_TIME", Date.now().toString()],
                ["CONTACTS_JSON", JSON.stringify(contacts)],
              ]);
            })
            .catch(error => Logger.error(error));
        } catch (error) {
          Logger.error(error);
        }
      }

      getContactsAsync();
    },
    [CACHE_CHECK, contactsPermission]
  );

  // On Load - check/update Contacts Permissions.
  useEffect(function getContactsPermission() {
    if (isMobilePlatform) {
      (async () => {
        const permission = await Contacts.requestPermissionsAsync();
        setContactsPermission(permission.status);
      })();
    }
  }, []);

  useEffect(function getFriendsOfFriends() {
    axios
      .get(`${API_HOST}/getFriendsOfFriends`)
      .then(res => {
        const { data }: { data: FriendsOfFriendsResponse } = res;
        const fof: FriendOfFriends[] = Object.values(data.friendInfo).map(f => {
          return {
            ...f,
            mutualFriends: data.newFriendsToFriendIds[f.id] as string[],
          };
        });
        setFriendsOfFriends(fof);
        setIsLoadingFoF(false);
      })
      .catch(error => {
        Logger.error(error);
        setIsLoadingFoF(false);
      });
  }, []);

  // TODO - add phone number when available
  useEffect(
    function getCurrentFriends() {
      const friendEmails = new Set<string>();
      friends.forEach(f => f.email && friendEmails.add(f.email.toLowerCase()));
      setFriendEmails(friendEmails);
    },
    [friends]
  );

  if (isLoadingContacts) {
    return <Loading />;
  }

  const data = {
    permissionGranted: contactsPermission === Contacts.PermissionStatus.GRANTED,
    fetchError: Object.values(fetchState).find(s => s.error),
    friendsOfFriends: friendsOfFriends.filter(
      ({ id, email }) =>
        addedInCurrentSession.has(id) || !friendEmails.has(email)
    ),
    contacts: filteredContacts,
    friends,
    fetchState,
    friendEmails,
    isLoadingFoF,
    smsSupported,
    challengeHabitId:
      layout === Layouts.MOBILE && route.params?.challengeHabitId,
    routeName: route?.name,
  };

  const { doShowShareScreen } = sharingHelper;
  const actions = {
    addFriend: addFriendAction,
    doShowShareScreen,
  };

  return <AddFriendView {...data} {...actions} />;
};
