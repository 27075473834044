import React, { useState } from "react";
import { View, StyleSheet, Text } from "react-native";
import { SectionSubtitle } from "components";
import { ShareLink } from "components/Invite/ShareLink";
import { FeedbackModal } from "components/Feedback/FeedbackModal";
import { USER_PROFILE_URL, doShareInviteLink } from "utils/sharing";
import { useGetAuth } from "redux/selectors";
import { KButton } from "components/UI/KButton";
import { COLORS } from "utils/appStyles";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { FeedbackSource } from "types/feedback";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    width: "90%",
    alignSelf: "center",
  },
  button: {
    marginTop: 10,
  },
  centeredRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

export const AddFriendWidget = () => {
  const { profile } = useGetAuth();
  const navigation = useNavigation();
  const route = useRoute();
  const dispatch = useDispatch();
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);

  //TODO: font weight and selectable text do not work on Android
  return (
    <>
      <View style={styles.container}>
        <SectionSubtitle
          style={{ textAlign: "center", marginBottom: 20 }}
          selectable
        >
          Add friends with your invite link, or just share your username:{" "}
          <Text style={{ fontWeight: "500" }}>{profile.handle}</Text>
        </SectionSubtitle>
        <ShareLink
          link={`${USER_PROFILE_URL}${profile.handle}`}
          onPress={() => {
            doShareInviteLink(profile, dispatch);
          }}
          type="profile_link_widget"
          routeName={route.name}
        />
        <View style={styles.centeredRow}>
          <KButton
            label="More Options"
            mode="text"
            color={COLORS.accent}
            style={styles.button}
            onPress={() => navigation.navigate("AddFriendScreen")}
          />
          <KButton
            label="Friend Match 🎲"
            mode="text"
            color={COLORS.accent}
            style={styles.button}
            onPress={() => {
              setShouldShowModal(true);
            }}
          />
        </View>
      </View>
      {shouldShowModal && (
        <FeedbackModal
          source={FeedbackSource.FIND_FRIEND}
          setShouldShowModal={setShouldShowModal}
          title="Match with a friend"
          placeholder="Tell us what kind of habits you're building and we'll match you with a friend..."
        />
      )}
    </>
  );
};
