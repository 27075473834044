import React, { memo } from "react";
import { MessageUIComponentProps, MessageSimple } from "utils/stream";
import { MessageType } from "types/message";
import { NoteMessage } from "components/Chat/NoteMessage";
import { KMessageText } from "components/Chat/KMessageText";

export const KMessage = memo((props: MessageUIComponentProps) => {
  const { message } = props;
  if (message.kCustomType === MessageType.NOTE_UPDATE) {
    return <NoteMessage streamProps={props} data={message.kData} />;
  }

  return <MessageSimple {...props} MessageText={KMessageText} />;
});
