import React, { useRef } from "react";
import { View, ScrollView, StyleSheet, SafeAreaView } from "react-native";

import { Logger } from "utils/Logger";
import { useGetHabitsCount } from "redux/selectors";
import { HabitGrid } from "components";
import { styles as headerStyles } from "components/UI/KHeader";
import { Body } from "components/UI/Typography";
import { HabitTitleRow } from "components/HabitGrid/HabitTitleRow";
import { HabitTitleRowWide } from "components/HabitGrid/HabitTitleRowWide";
import { KAvatar } from "components/UI/KAvatar";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";
import { HabitListChallengeInvites } from "components/Challenges/ChallengeInvites";
import { AddFriendPromo } from "components/Promos/AddFriendPromo";
import { ReviewCard } from "components/RatingPrompt/ReviewCard";
import { GrantNotificationsCard } from "components/Notifications/GrantNotificationsCard";
import { RoutineInvites } from "components/Routines/RoutineInvites";
import { FeedList } from "components/Feed/FeedList";
import { MotivationButtonWrapper } from "components/Motivation/MotivationButton";
import { useLayoutContext, Layouts } from "contexts";
import { isMobilePlatform } from "utils/helpers";

export const HabitList = ({
  ownHabit,
  friendAvatar,
  setScrollPosition,
  friendId,
}: {
  ownHabit: boolean;
  friendAvatar?: string;
  setScrollPosition?: React.Dispatch<React.SetStateAction<number>>;
  friendId?: string;
}) => {
  Logger.log("HabitList re-rendering");
  const habitsCount = useGetHabitsCount(friendId);
  const layout = useLayoutContext();

  const { styles: gridStyles, GRID_LEFT_WIDTH } = useHabitGridStyles();
  const scrollViewRef = useRef(null);

  if (habitsCount === undefined) {
    return null;
  }

  const placeholderArray = Array(habitsCount).fill(0);

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        style={styles.container}
        onScroll={event => {
          if (setScrollPosition)
            setScrollPosition(event.nativeEvent.contentOffset.y);
        }}
        scrollEventThrottle={16}
        ref={scrollViewRef}
      >
        <HabitGrid ownHabit={ownHabit} friendId={friendId} />
        <View style={[styles.titles, { width: GRID_LEFT_WIDTH }]}>
          <View style={[gridStyles.headerCell, headerStyles.headerStyle]}>
            {ownHabit ? (
              <Body style={headerStyles.motivationHeader}>SnapHabit</Body>
            ) : friendAvatar ? (
              <KAvatar source={{ uri: friendAvatar }} size={45} />
            ) : null}
          </View>
          {placeholderArray.map((_, index) =>
            layout === Layouts.MOBILE ? (
              <HabitTitleRow
                key={index}
                index={index}
                ownHabit={ownHabit}
                friendid={friendId}
              />
            ) : (
              <HabitTitleRowWide
                key={index}
                index={index}
                friendid={friendId}
              />
            )
          )}
        </View>
        {ownHabit && isMobilePlatform && (
          <MotivationButtonWrapper
            customStyle={styles.homescreenMotivationButton}
          />
        )}
        {ownHabit && isMobilePlatform && (
          <ReviewCard scrollViewRef={scrollViewRef} />
        )}
        {ownHabit && isMobilePlatform && (
          <GrantNotificationsCard scrollViewRef={scrollViewRef} />
        )}
        {ownHabit && (
          <HabitListChallengeInvites style={styles.challengeInvites} />
        )}
        {ownHabit && <RoutineInvites style={styles.invites} />}
        {ownHabit && isMobilePlatform && <FeedList />}
        {isMobilePlatform && <AddFriendPromo style={styles.addFriendPromo} />}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  titles: {
    position: "absolute",
    top: 0,
    left: 0,
    flex: 1,
    backgroundColor: "white",
    zIndex: 1,
  },
  habitInfoContainer: {
    position: "absolute",
    top: 0,
  },
  challengeInvites: {
    marginTop: 10,
    marginBottom: 20,
  },
  invites: {
    marginBottom: 20,
  },
  addFriendPromo: {
    marginTop: 40,
  },
  homescreenMotivationButton: {
    marginTop: 10,
    alignItems: "center",
  },
});
