import React from "react";
import { StyleProp, ViewStyle, TextStyle } from "react-native";
import { Button } from "react-native-paper";

export const KButton = ({
  icon = null,
  color = "",
  mode = "contained",
  onPress,
  label,
  disabled = false,
  style = {},
  labelStyle = {},
  loading = false,
  ...rest
}: {
  icon?: string;
  color?: string;
  mode?: "text" | "outlined" | "contained";
  onPress: () => void;
  label: string;
  disabled?: boolean;
  loading?: boolean;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  uppercase?: boolean;
  contentStyle?: StyleProp<ViewStyle>;
}) => (
  <Button
    color={color}
    icon={icon}
    mode={mode}
    onPress={onPress}
    style={style}
    labelStyle={labelStyle}
    disabled={disabled}
    loading={loading}
    {...rest}
  >
    {label}
  </Button>
);
