import React from "react";
import { StyleSheet, Text, View } from "react-native";
import moment from "moment";
import { useGetSpecificHabit, useGetDay } from "redux/selectors";
import { DayStatus } from "types/habits";
import { NoteEditor } from "components/Notes/NoteEditor";
import { renderHabitTitle } from "utils/strings";

const styles = StyleSheet.create({
  container: { flex: 1 },
  header: { fontSize: 20, textAlign: "center", margin: 4 },
});

const getStatusString = (status: DayStatus) => {
  return status === DayStatus.SUCCESS
    ? "Success"
    : status === DayStatus.FAIL
    ? "Failed"
    : "Skipped";
};

export const NoteView = props => {
  const { habitid, date, ownHabit } = props;

  const { habit } = useGetSpecificHabit(habitid);
  //! Day may not exist if shared days aren't loaded
  //! So do not assume we have a day (unless its your own note)
  const day = useGetDay({ habitid, date, ownHabit });

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{renderHabitTitle(habit)}</Text>
      <Text style={styles.header}>
        {`${moment(date, "MM-DD-YYYY").format("MMMM D")}${
          day ? ` | ${getStatusString(day.status)}` : ""
        }`}
      </Text>
      <NoteEditor {...props} />
    </View>
  );
};
