import React from "react";
import { View, SafeAreaView, FlatList, StyleSheet } from "react-native";

import { RoutineInviteCard } from "./RoutineInviteCard";
import { useGetRoutines } from "redux/selectors";

const styles = StyleSheet.create({
  container: { flex: 1 },
  routineListContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20,
  },
  routineCardContainer: {
    paddingVertical: 10,
  },
});

export const RoutineListView = () => {
  const routines = useGetRoutines();

  const renderItem = ({ item }) => {
    return (
      <View style={styles.routineCardContainer}>
        <RoutineInviteCard
          id={item.id}
          hideItem={() => null}
          isShown={true}
          showDismissButton={false}
        />
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={routines}
        renderItem={renderItem}
        contentContainerStyle={styles.routineListContainer}
      />
    </SafeAreaView>
  );
};
