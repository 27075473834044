/// <reference lib="dom" />
// imports dom lib for tsc for backend for this specific file
import firebase from "firebase/app";
import { LanguageCode } from "types/language";

export enum TokenStatus {
  Granted = "GRANTED",
  Denied = "DENIED",
  SignedOut = "SIGNED_OUT",
  DeviceNotRegistered = "DEVICE_NOT_REGISTERED",
}

export type TokenEntry = {
  experienceId: string;
  lastUpdateDate: any;
  platform: "ios" | "android" | "windows" | "macos" | "web"; // don't use typeof Platform since we import on backend
} & (
  | {
      status: TokenStatus.Denied;
      token?: string;
    }
  | { status: TokenStatus.SignedOut; token?: string }
  | { status: TokenStatus.DeviceNotRegistered; token?: string }
  | {
      status: TokenStatus.Granted;
      token: string;
    }
);

export type TokensMap = {
  [installationId: string]: TokenEntry;
};

export type FirestoreUserData = {
  data?: object;
  tokens?: TokensMap;
  wantShowAsFriendOfFriends: boolean;
  isSignedUp: boolean;
  handle: string;
  habitOrdering: string[];
  photoURL: string;
  displayName: string;
  language?: LanguageCode;
};

export type User = firebase.User & FirestoreUserData;
