import React from "react";
import { SvgXml } from "react-native-svg";
import { Platform, View } from "react-native";

const xml = `<svg viewBox="0 0 8 8"><path d="M5.571,7.455H8V2.741a.428.428,0,0,0-.429-.429H.429A.428.428,0,0,0,0,2.741V9.884a.428.428,0,0,0,.429.429H5.143V7.884A.43.43,0,0,1,5.571,7.455Zm2.3.982-1.75,1.75a.428.428,0,0,1-.3.125H5.714V8.027H8v.109A.427.427,0,0,1,7.875,8.438Z" transform="translate(0 -2.313)"/></svg>`;

export const NoteIcon = ({ style, ...rest }) =>
  Platform.OS === "web" ? (
    // RN web, style on the actual svg itself seems to break
    <View style={style}>
      <svg viewBox="0 0 8 8" {...rest}>
        <path
          d="M5.571,7.455H8V2.741a.428.428,0,0,0-.429-.429H.429A.428.428,0,0,0,0,2.741V9.884a.428.428,0,0,0,.429.429H5.143V7.884A.43.43,0,0,1,5.571,7.455Zm2.3.982-1.75,1.75a.428.428,0,0,1-.3.125H5.714V8.027H8v.109A.427.427,0,0,1,7.875,8.438Z"
          transform="translate(0 -2.313)"
        />
      </svg>
    </View>
  ) : (
    <SvgXml xml={xml} style={style} {...rest} />
  );
