// @refresh reset
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import { View, Text, StyleSheet, SectionList } from "react-native";
import { shallowEqual, useDispatch } from "react-redux";
import { MaterialIcons } from "@expo/vector-icons";
import { Appbar, useTheme } from "react-native-paper";

import {
  Body,
  ChallengeUpdateCard,
  KButton,
  KMediaViewer,
  Subtitle,
  ZeroState,
  Loading,
} from "components";
import { MotivationButton } from "components/Motivation/MotivationButton";

import { getSchedule, pluralize, renderHabitTitle } from "utils/strings";
import {
  logJoinGroupHabit,
  logJoinRoutine,
  logShareButtonPress,
} from "utils/analytics";

import { useSelector } from "redux/store";
import {
  isHabitChallengeParticipant,
  HabitChallenge,
  Habit,
  Note,
  isHabitChallenge,
  isHabitRoutine,
} from "types/habits";
import { MotivationType } from "types/friends";
import { StackProps } from "layouts/MobileLayout";
import {
  useGetAuth,
  useGetSharedDays,
  useGetSpecificHabit,
} from "redux/selectors";
import { getSharedNotes } from "redux/slices";
import { ChallengeHabitGrid } from "components/HabitGrid/HabitGrid";
import { HabitGridData } from "components/HabitGrid/ProgressColumn";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";
import { ShareButton } from "components/Sharing/ShareButton";
import { doShare } from "utils/routines";
import { ProgressTrackerAvatar } from "components/Challenges/ProgressTrackerAvatar";
import { useHistory } from "utils/react-router";
import { useLayoutContext, Layouts } from "contexts";
import { LinkInvite } from "components/Challenges/LinkInvite";
import { doShareChallengeInviteLink } from "utils/sharing";

const NUM_DAYS_TO_ADD = 10;

type HabitChallengeGridData = HabitGridData & { uid: string };

const ProgressTracker = ({
  challengeHabits,
  mainHabit,
  mainHabitId,
  userIsChallengeOwner,
}: {
  challengeHabits: HabitChallengeGridData[];
  mainHabit: HabitChallenge;
  mainHabitId: string;
  startDate: moment.Moment;
  userIsChallengeOwner: boolean;
}) => {
  const { uid: ownerUID, challengerInfo } = mainHabit;
  const { styles: gridStyles, COLUMN_WIDTH } = useHabitGridStyles();
  const avatarStyle = [styles.alignCenter];
  return (
    <View
      style={
        // We need the avatar column to be absolute positioned
        // so that the badge renders over the grid
        // Because of that, we need to explicitly calculate the height
        // of this component. Add 1 because we are rendering the add friend button
        {
          height:
            COLUMN_WIDTH * (challengeHabits.length + 1) +
            gridStyles.headerCell.height,
        }
      }
    >
      <View
        style={[
          gridStyles.column,
          {
            position: "absolute",
            zIndex: 100,
            backgroundColor: "white",
            width: 1.5 * COLUMN_WIDTH,
          },
        ]}
      >
        <View
          style={[
            gridStyles.headerCell,
            {
              borderBottomWidth: 0,
            },
          ]}
        />
        {challengeHabits.map(h => (
          <ProgressTrackerAvatar
            key={h.uid}
            uid={h.uid}
            userDisplayName={challengerInfo?.[h.uid].name}
            userPhotoURL={challengerInfo?.[h.uid].photoURL}
            habitid={h.id}
            isChallengeOwner={h.uid === ownerUID}
          />
        ))}
        {userIsChallengeOwner && (
          <View style={avatarStyle}>
            <ShareButton
              habitid={mainHabitId}
              style={styles.shareStyle}
              source="HabitChallengeView"
            />
          </View>
        )}
      </View>
      <ChallengeHabitGrid habits={challengeHabits} />
    </View>
  );
};

const Header = ({
  mainHabit,
  mainHabitId,
  thisUsersHabitInfo,
  challengeHabits,
}: {
  mainHabit: HabitChallenge;
  mainHabitId: string;
  thisUsersHabitInfo?: HabitChallengeGridData;
  challengeHabits: HabitChallengeGridData[];
}) => {
  const userIsInChallenge = !!thisUsersHabitInfo;
  const userIsChallengeOwner =
    userIsInChallenge && mainHabit.uid === thisUsersHabitInfo.uid;

  const navigation = useNavigation<
    StackNavigationProp<StackProps, "HabitChallengeScreen">
  >();
  const history = useHistory();
  const layout = useLayoutContext();

  const startDate = useMemo(
    () =>
      mainHabit && mainHabit.createdAt
        ? moment(mainHabit.createdAt.toDate())
        : null,
    [mainHabit]
  );
  const numParticipants =
    mainHabit && mainHabit.challengerInfo
      ? Object.keys(mainHabit.challengerInfo).length
      : 0;
  return (
    <>
      <View style={styles.sectionHeader}>
        <View style={styles.rowLayout}>
          <View style={{ flex: 1 }}>
            {mainHabit.title !== "" && (
              <Subtitle>{renderHabitTitle(mainHabit)}</Subtitle>
            )}
            {mainHabit.description !== "" && (
              <Body style={styles.text}>{mainHabit.description}</Body>
            )}
            <Text style={styles.text}>
              {startDate
                ? `Started ${startDate.format("MMMM D, YYYY")} | `
                : ""}
              {numParticipants} Participant{pluralize(numParticipants)}
              {numParticipants !== challengeHabits.length
                ? ` (${challengeHabits.length} visible to you)`
                : ""}
            </Text>
            <Text>
              Frequency:{" "}
              {getSchedule(
                mainHabit.schedule,
                mainHabit.frequencyType,
                mainHabit.frequency
              )}
            </Text>
          </View>
          {numParticipants > 1 && (
            <MotivationButton
              habitid={mainHabitId}
              motivationType={MotivationType.GROUP}
              customStyle={styles.groupMotivationButton}
            />
          )}
        </View>

        {(userIsChallengeOwner || mainHabit.isLinkInviteEnabled) && (
          <LinkInvite challengeHabitId={mainHabitId} />
        )}
        {!userIsInChallenge && (
          <KButton
            mode="outlined"
            label="Join Habit"
            color="black"
            icon="flag"
            style={{ margin: 5 }}
            onPress={() => {
              logJoinGroupHabit(mainHabit, "HabitChallengeView");
              if (isHabitRoutine(mainHabit)) {
                logJoinRoutine(mainHabit, "HabitChallengeView");
              }
              if (layout === Layouts.MOBILE) {
                navigation.navigate("AddHabitScreen", {
                  habitChallenge: mainHabit,
                  habitChallengeId: mainHabitId,
                });
              } else {
                history.push("/me/addHabit", {
                  habitChallengeId: mainHabitId,
                });
              }
            }}
          />
        )}
      </View>
      <ProgressTracker
        challengeHabits={challengeHabits}
        mainHabit={mainHabit}
        mainHabitId={mainHabitId}
        startDate={startDate}
        userIsChallengeOwner={userIsChallengeOwner}
      />
    </>
  );
};

const renderSectionHeader = ({ section }) => (
  <Subtitle style={styles.sectionHeader}>
    {section.date.format("MMMM D")}
  </Subtitle>
);

type NoteSectionItem = {
  uid: string;
  date: string;
  habitid: string;
};

const keyExtractor = ({ uid, date }: NoteSectionItem) => `${uid}-${date}`;

export const HabitChallengeView = ({ habitid }: { habitid: string }) => {
  const navigation = useNavigation<StackNavigationProp<StackProps>>();
  const theme = useTheme();
  const { auth, profile } = useGetAuth();
  const { habit } = useGetSpecificHabit(habitid);
  const { isSharedDaysLoaded } = useGetSharedDays();
  const dispatch = useDispatch();

  const mainHabitId = useMemo(
    () =>
      isHabitChallenge(habit)
        ? habitid
        : isHabitChallengeParticipant(habit)
        ? habit.challengeHabitId
        : null,
    [habit, habitid]
  );

  const { habit: mainHabit } = useGetSpecificHabit(mainHabitId) as {
    isLoaded: boolean;
    habit: HabitChallenge;
  };

  const challengeHabitsSelector = useMemo(
    () =>
      createSelector(
        Object.values(mainHabit?.challengerInfo ?? {})
          .map(info =>
            "habitid" in info
              ? state =>
                  state.firestore.ordered?.challenges.find(
                    h => h.id === info.habitid
                  )
              : null
          )
          .filter(s => s),
        (...challenges) => {
          // a user may not have access to all habits
          // if they are viewing someone elses challenge
          // and they are not friends with those participants
          return challenges.filter(s => s);
        }
      ),
    [mainHabit?.challengerInfo]
  );

  const selectedChallengeHabits: Habit[] = useSelector(
    challengeHabitsSelector,
    shallowEqual
  );

  // This tells us what we can show
  const challengeHabits = useMemo(
    () => [{ ...mainHabit, id: mainHabitId }, ...selectedChallengeHabits],
    [mainHabit, mainHabitId, selectedChallengeHabits]
  );

  // This is all we need for actual rendering
  const habitsForRendering = useMemo(
    () =>
      challengeHabits
        ? challengeHabits.map(h => ({
            id: h.id,
            uid: h.uid,
            ownHabit: h.uid === auth.uid,
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only update when length changes
    [challengeHabits.length, auth]
  );

  const thisUsersHabitInfo = useMemo(
    () => habitsForRendering.find(h => h.ownHabit),
    [habitsForRendering]
  );

  useLayoutEffect(() => {
    const userIsChallengeOwner =
      thisUsersHabitInfo && mainHabit.uid === thisUsersHabitInfo.uid;

    // show "i" if ownHabit exists, i.e. if you are either a challenge participant or owner
    if (thisUsersHabitInfo) {
      navigation.setOptions({
        headerRight: () => (
          <View style={{ flexDirection: "row" }}>
            <Appbar.Action
              icon="account-plus"
              color={theme.colors.accent}
              onPress={() => {
                logShareButtonPress("header", "HabitChallengeView");
                if (userIsChallengeOwner && mainHabit.isLinkInviteEnabled) {
                  doShareChallengeInviteLink(
                    {
                      ...mainHabit,
                      id: thisUsersHabitInfo.id,
                    },
                    dispatch
                  );
                } else {
                  doShare(habit, profile);
                }
              }}
            />
            <Appbar.Action
              icon="information-outline"
              color={theme.colors.accent}
              onPress={() => {
                navigation.navigate("HabitScreen", {
                  title: renderHabitTitle(mainHabit),
                  habitid: thisUsersHabitInfo.id,
                });
              }}
            />
          </View>
        ),
      });
    }
  }, [
    dispatch,
    habit,
    mainHabit,
    navigation,
    profile,
    theme,
    thisUsersHabitInfo,
  ]);

  const [listDays, setListDays] = useState<moment.Moment[]>([]);
  const [noteSections, setNoteSections] = useState<
    {
      date: moment.Moment;
      data: NoteSectionItem[];
    }[]
  >([]);

  useEffect(() => {
    const NUM_NOTE_SECTIONS = NUM_DAYS_TO_ADD * 5;
    const arr = new Array(NUM_NOTE_SECTIONS);
    for (let i = 0; i < NUM_NOTE_SECTIONS; i++) {
      arr[i] = moment().subtract(i, "day");
    }
    setListDays(arr);
  }, []);

  const onEndReached = useCallback(() => {
    setListDays(listDays => {
      const NUM_NOTE_SECTIONS = NUM_DAYS_TO_ADD * 5;
      const arr = new Array(NUM_NOTE_SECTIONS);
      for (let i = 0; i < NUM_NOTE_SECTIONS; i++) {
        arr[i] = moment().subtract(listDays.length + i, "day");
      }
      return [...listDays, ...arr];
    });
  }, []);

  useEffect(() => {
    const habitIds = habitsForRendering.map(h => h.id);
    dispatch(getSharedNotes(habitIds));
  }, [dispatch, habitsForRendering]);

  const { habitToNoteMap: notes } = useSelector(
    state => state.notes,
    shallowEqual
  );

  useEffect(() => {
    const sections = [];
    for (let i = 0; i < listDays.length; i++) {
      const date = listDays[i].format("MM-DD-YYYY");
      const section = [];
      habitsForRendering.forEach(h => {
        const { id, uid } = h;
        if (notes?.[id]?.[date]) {
          section.push({ uid, date, habitid: id });
        }
      });

      if (section.length > 0) {
        sections.push({
          date: listDays[i],
          data: section,
        });
      }
    }

    setNoteSections(sections);
  }, [listDays, habitsForRendering, notes]);

  // Media modal
  const [selectedNoteMedia, setSelectedNoteMedia] = useState<Note["media"]>([]);
  const [imageViewerIsVisible, setImageViewerIsVisible] = useState<boolean>(
    false
  );
  const [imageViewerIndex, setImageViewerIndex] = useState<number>(0);

  const renderUpdateItem = useCallback(
    ({ item }: { item: NoteSectionItem }) => {
      return (
        <ChallengeUpdateCard
          {...item}
          mainHabit={mainHabit}
          setSelectedNoteMedia={setSelectedNoteMedia}
          setImageViewerIndex={setImageViewerIndex}
          setImageViewerIsVisible={setImageViewerIsVisible}
        />
      );
    },
    [mainHabit]
  );

  if (!mainHabit) {
    return <ZeroState header="Something went wrong..." />;
  }

  if (!isSharedDaysLoaded) {
    return <Loading />;
  }

  return (
    <View style={styles.container}>
      <KMediaViewer
        isVisible={imageViewerIsVisible}
        setIsVisible={setImageViewerIsVisible}
        index={imageViewerIndex}
        imageUrls={selectedNoteMedia.map(m => ({
          url: "",
          height: m.height,
          width: m.width,
          props: {
            local: m.uri,
            remote: m.url,
            type: m.type,
            style: { height: m.height, width: m.width },
          },
        }))}
      />
      <SectionList
        sections={noteSections}
        ListHeaderComponent={
          <Header
            mainHabit={mainHabit}
            mainHabitId={mainHabitId}
            thisUsersHabitInfo={thisUsersHabitInfo}
            challengeHabits={habitsForRendering}
          />
        }
        renderSectionHeader={renderSectionHeader}
        keyExtractor={keyExtractor}
        renderItem={renderUpdateItem}
        onEndReached={onEndReached}
        stickySectionHeadersEnabled={false}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    marginVertical: 4,
  },
  // General
  container: {
    flex: 1,
  },
  // Updates
  sectionHeader: {
    marginHorizontal: 20,
    marginTop: 16,
    marginBottom: 4,
  },
  rowLayout: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  alignCenter: {
    alignItems: "center",
  },
  shareStyle: {
    backgroundColor: "white",
    overflow: "visible",
    borderRadius: 50,
    elevation: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: "#CECECE",
    shadowOpacity: 1,
    shadowRadius: 2,
  },
  groupMotivationButton: {
    justifyContent: "center",
    marginLeft: 15,
  },
});
