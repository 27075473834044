import React from "react";
import { useTheme } from "react-native-paper";
import { TabBar } from "react-native-tab-view";

export const KTabBar = props => {
  const theme = useTheme();

  return (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: theme.colors.accent }}
      labelStyle={{ color: theme.colors.accent }}
      style={{
        backgroundColor: theme.colors.primary,
      }}
    />
  );
};
