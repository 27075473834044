import { Platform } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { Notifications } from "expo";
import moment from "moment";
import * as Analytics from "expo-firebase-analytics";
import * as Linking from "expo-linking";

import { Day, DayStatus } from "types/habits";
import { AndroidChannel } from "types/notifications";
import { getAbsoluteDays } from "utils/time";
import { logNotificationTap } from "utils/analytics";
import { setSingleDay } from "redux/slices";
import { StackProps } from "../layouts/MobileLayout";
import { Logger } from "./Logger";
import { NavigationService } from "./NavigationService";
import axios from "./axios";
import { API_HOST } from "./constants";
import { isMobilePlatform } from "./helpers";

if (isMobilePlatform) {
  Notifications.createCategoryAsync("CHAT_MESSAGE", [
    {
      actionId: "REPLY",
      buttonTitle: "Quick Reply",
      textInput: { submitButtonTitle: "Send", placeholder: "Your message" }, //! It is unclear right now how to get this data
      isDestructive: false,
      isAuthenticationRequired: false,
      doNotOpenInForeground: true,
    },
  ]);
  Notifications.createCategoryAsync("REMINDER", [
    {
      actionId: "markComplete",
      buttonTitle: "✅",
      isDestructive: false,
      isAuthenticationRequired: false,
    },
    {
      actionId: "addNote",
      buttonTitle: "✅ and 📝",
      textInput: { submitButtonTitle: "Add note", placeholder: "Type here..." },
      isDestructive: false,
      isAuthenticationRequired: false,
    },
  ]);
}

if (Platform.OS === "android") {
  Notifications.createChannelAndroidAsync(AndroidChannel.CHAT_MESSAGE, {
    name: "Chat",
    sound: true,
    priority: "max",
    vibrate: [0, 250, 250, 250],
  });
  Notifications.createChannelAndroidAsync(AndroidChannel.REMINDER, {
    name: "Reminders",
    sound: true,
    priority: "max",
    vibrate: [0, 250, 250, 250],
  });
  Notifications.createChannelAndroidAsync(AndroidChannel.HABIT_UPDATE, {
    name: "Habit Updates",
    sound: true,
    priority: "max",
    vibrate: [0, 250, 250, 250],
  });
  Notifications.createChannelAndroidAsync(AndroidChannel.FRIEND_INVITE, {
    name: "Friend Requests",
    sound: true,
    priority: "max",
    vibrate: [0, 500],
  });
  Notifications.createChannelAndroidAsync(AndroidChannel.CHALLENGE_INVITE, {
    name: "Habit Invites",
    sound: true,
    priority: "max",
    vibrate: [0, 500],
  });
  Notifications.createChannelAndroidAsync(AndroidChannel.MOTIVATION, {
    name: "Motivation",
    sound: true,
    priority: "max",
    vibrate: [0, 250, 250, 250],
  });
}

const isOnChatScreen = (friend: { id: string }) => {
  const state = NavigationService.getCurrentState();
  const route = state.routes[state.index] as RouteProp<
    StackProps,
    "ChatScreen"
  >;

  return route?.name === "ChatScreen" && route?.params?.friendid === friend.id;
};

const handleReminderActions = async (
  reduxFirebase,
  actionId,
  userText,
  data
) => {
  const m = moment();
  const date = m.format("MM-DD-YYYY");
  const absoluteDay = getAbsoluteDays(m);
  const { habitid, uid } = data;

  try {
    Analytics.logEvent("reminder_notification_press", {
      action: actionId === "markComplete" ? "mark_complete" : "add_note",
    });
    logNotificationTap(
      actionId === "markComplete"
        ? "reminder_mark_complete"
        : "reminder_add_note"
    );

    const res = await axios.post(`${API_HOST}/updateDay`, {
      habitid,
      newStatus: DayStatus.SUCCESS,
      date,
      uid,
      absoluteDay,
      actionId,
      userText: userText ?? "",
    });
    const day: Day = res.data;
    reduxFirebase.dispatch(setSingleDay(day));
  } catch (err) {
    Logger.warning("handleReminderActions caught error");
    Logger.error(err);
  }
};

export const createNotificationsListener = reduxFirebase =>
  Notifications.addListener(async notification => {
    const { origin, data, remote, actionId, userText } = notification;

    Logger.log(origin, data.notificationType, remote, notification);
    const { notificationType } = data;
    await NavigationService.navigationPromise;
    if (
      // Notification was tapped
      origin === "selected" ||
      // This second case means the notification was tapped
      // while notification was in foreground
      (origin === "received" && !remote)
    ) {
      axios.post(`${API_HOST}/logNotification`, { data });
      if (
        notificationType &&
        (notificationType === "CHAT_MESSAGE" ||
          notificationType === "MOTIVATION")
      ) {
        logNotificationTap(
          notificationType === "MOTIVATION" ? "motivation" : "chat"
        );
        const { deepLink }: { deepLink: string } = data;
        Linking.openURL(deepLink);
      } else if (notificationType && notificationType === "FRIEND") {
        // Old way to make sure friend is there when navigating
        // when we were using FirestoreContext
        // if (data.docid && data.invite) {
        //   setFriends(f => ({ ...f, [data.docid]: data.invite }));
        // }
        logNotificationTap("friend");
        NavigationService.navigate("Home", {
          screen: "Friends",
        });
      } else if (notificationType && notificationType === "REMINDER") {
        // fires next time app is opened, not when notification action is pressed
        if (actionId) {
          handleReminderActions(reduxFirebase, actionId, userText, data);
        } else {
          Analytics.logEvent("reminder_notification_press", {
            action: "default",
          });
          logNotificationTap("reminder");
          NavigationService.navigate("Home", {
            screen: "Habits",
          });
        }
      }
      // User is in the app - by default, notification will not show.
      // Render it manually if user is not in the chat room.
      // remote = true otherwise infinite loop when render the local notification
      // We pass through the notification object sent from the backend.
    } else if (origin === "received" && remote) {
      if (notificationType && notificationType === "CHAT_MESSAGE") {
        const {
          friend,
          notification,
        }: {
          friend: { name: string; id: string };
          notification: {
            title: string;
            body?: string;
            data?: any;
            categoryId?: string;
          };
        } = data;
        // If we're not already on this chat screen, render a notification
        if (!isOnChatScreen(friend)) {
          Notifications.presentLocalNotificationAsync(notification);
        }
      }
    }
  });

export const SEGMENTED_CONTROL_OPTIONS = ["None", "Highlights", "All"];
