import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { View, StyleSheet, Text } from "react-native";

import { NoteUpdateData } from "types/message";
import { Logger } from "utils/Logger";
import firebase from "utils/firebase";
import { StackProps } from "layouts/MobileLayout";
import { truncate } from "utils/strings";
import { useDispatch } from "react-redux";
import { setToast, ToastTypes } from "redux/slices";
import { useGetAuth } from "redux/selectors";
import { NoteMessageLayout } from "components/Chat/NoteMessageLayout";
import { MessageUIComponentProps } from "utils/stream";
import { useLayoutContext, Layouts } from "contexts";
import { useHistory } from "utils/react-router";

const getHabitAndDay = (habitid: string, dayid: string) => {
  return Promise.all([
    firebase.firestore().doc(`habits/${habitid}`).get(),
    firebase.firestore().doc(`habits/${habitid}/days/${dayid}`).get(),
  ]);
};

export const NoteMessage = ({
  data,
  streamProps,
}: {
  data: NoteUpdateData;
  streamProps: MessageUIComponentProps;
}) => {
  const navigation = useNavigation<StackNavigationProp<StackProps>>();
  const { auth } = useGetAuth();
  const dispatch = useDispatch();
  const layout = useLayoutContext();
  const history = useHistory();
  const { message } = streamProps;
  const ownHabit = message.user.id === auth.uid;

  const { title, habitid, dayid, status, date } = data as NoteUpdateData;
  const onPress = async () => {
    try {
      if (ownHabit) {
        if (layout === Layouts.MOBILE) {
          navigation.navigate("EditDayNoteScreen", {
            habitid,
            date,
            dayid,
            ownHabit: true,
            editable: false,
          });
        } else {
          history.push(`/me/notes/${habitid}/${date}`);
        }
      } else {
        const [habit, day] = await getHabitAndDay(habitid, dayid);
        if (!habit.exists || !day.exists) {
          return;
        }

        if (layout === Layouts.MOBILE) {
          navigation.navigate("EditDayNoteScreen", {
            habitid,
            dayid,
            date,
            ownHabit: false,
            friendid: message.user.id,
            editable: false,
          });
        } else {
          history.push(`/friend/${message.user.id}/notes/${habitid}/${date}`);
        }
      }
    } catch (error) {
      if (error.code === "permission-denied") {
        dispatch(setToast({ type: ToastTypes.PERMISSION_DENIED }));
      } else {
        Logger.error(error);
      }
    }
  };

  const Header = (
    <View style={styles.header}>
      <Text style={styles.headerText}>{truncate(title, 40)}</Text>
    </View>
  );

  return (
    <NoteMessageLayout
      Header={Header}
      onPress={onPress}
      streamProps={streamProps}
      ownHabit={ownHabit}
    />
  );
};

const styles = StyleSheet.create({
  header: {
    alignSelf: "center",
    backgroundColor: "white",
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 4,
    borderRadius: 4,
  },
  headerText: {
    textAlign: "center",
  },
});
