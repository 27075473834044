import React from "react";
import { PanelRight } from "panels/subpanels/PanelRight";
import { NoteEditor } from "components/Notes/NoteEditor";
import { MatchProps, Redirect } from "utils/react-router";
import { useGetDay } from "redux/selectors";

export const NotesPanel = ({
  match,
}: {
  match: MatchProps<{ habitid: string; date: string }>;
}) => {
  const { path, params } = match;
  const { habitid, date } = params;
  const ownHabit = path.startsWith("/me");

  const day = useGetDay({ habitid, date, ownHabit });

  if (!day?.id) {
    return <Redirect to="/me" />;
  }

  return (
    <PanelRight>
      <NoteEditor
        dayid={day.id}
        habitid={habitid}
        date={day.date}
        ownHabit={ownHabit}
        isEditable={ownHabit}
      />
    </PanelRight>
  );
};
