import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { useTheme } from "react-native-paper";
import { KButton } from "components/UI/KButton";
import { useRoute } from "@react-navigation/native";
import { logShareButtonPress } from "utils/analytics";

const styles = StyleSheet.create({
  linkContainer: {
    borderWidth: 1,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    height: 40,
  },
  button: {
    position: "absolute",
    right: 0,
    height: "100%",
  },
  link: {
    padding: 8,
    flex: 1,
  },
});

export const ShareLink = ({
  link,
  onPress,
  type,
  routeName,
}: {
  link: string;
  onPress: () => void;
  type: "profile_link_widget" | "habit_link_widget";
  routeName: string;
}) => {
  const theme = useTheme();

  return (
    <View
      style={[styles.linkContainer, { borderColor: theme.colors.disabled }]}
    >
      <Text selectable style={styles.link}>
        {link}
      </Text>
      <KButton
        label="SHARE"
        style={styles.button}
        color={theme.colors.accent}
        onPress={() => {
          logShareButtonPress(type, routeName);
          onPress();
        }}
      />
    </View>
  );
};
