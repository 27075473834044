import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useLayoutEffect } from "react";
import { Appbar, useTheme } from "react-native-paper";
import { MaterialIcons } from "@expo/vector-icons";

import { HabitDetailsView } from "components";

import { useGetSpecificHabit, useGetAuth } from "redux/selectors";
import { StackProps } from "layouts/MobileLayout";
import { View } from "react-native";
import { doShare } from "utils/routines";
import { renderHabitTitle } from "utils/strings";
import { logShareButtonPress } from "utils/analytics";
import { EditHabitOverflow } from "components/EditHabit/EditHabitOverflow";

export const HabitScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationProp<StackProps, "HabitScreen">;
  route: RouteProp<StackProps, "HabitScreen">;
}) => {
  const { habitid } = route.params;
  const { auth, profile } = useGetAuth();
  const { habit } = useGetSpecificHabit(habitid);
  const theme = useTheme();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: habit ? renderHabitTitle(habit) : "",
      headerRight: () => (
        <View style={{ flexDirection: "row" }}>
          <Appbar.Action
            icon="account-plus"
            color={theme.colors.accent}
            onPress={() => {
              if (auth.isAnonymous) {
                navigation.navigate("LoginScreen", {
                  reason: "addFriend",
                });
              } else {
                logShareButtonPress("header", "HabitDetails");
                doShare(habit, profile);
              }
            }}
          />
          <EditHabitOverflow
            navigation={navigation}
            theme={theme}
            habit={habit}
            habitid={habitid}
          />
        </View>
      ),
    });
  }, [habit, habitid, navigation, profile, theme, auth]);

  if (!habit) {
    return null;
  }

  return <HabitDetailsView habitid={habitid} habit={habit} ownHabit />;
};
