import React, { memo } from "react";
import { Text, StyleSheet, View } from "react-native";
import { FlatList, TouchableOpacity } from "react-native-gesture-handler";
import { useGetFriend } from "redux/selectors";
import { KAvatar } from "components/UI/KAvatar";
import { useLayoutContext, Layouts } from "contexts/LayoutContext";
import { useNavigation } from "@react-navigation/native";
import { AddFriendIconButton } from "components/Friends/AddFriendIconButton";
import { firstName } from "utils/strings";

const styles = StyleSheet.create({
  list: { flexGrow: 0 },
  avatar: {
    margin: 5,
  },
  itemContainer: {
    alignItems: "center",
    marginVertical: 5,
    marginHorizontal: 5,
    height: 100,
    width: 80,
  },
});

const StartChatCarouselItem = ({ id }: { id: string }) => {
  const { friend } = useGetFriend(id);
  const layout = useLayoutContext();
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.itemContainer}
      onPress={() => {
        if (layout === Layouts.MOBILE) {
          navigation.navigate("ChatScreen", {
            title: friend.name,
            friendid: id,
          });
        }
      }}
    >
      <KAvatar
        source={{ url: friend.avatar }}
        size={60}
        style={styles.avatar}
      />
      <Text numberOfLines={1}>{firstName(friend.name)}</Text>
    </TouchableOpacity>
  );
};

const CarouselItem = memo(StartChatCarouselItem);

export const StartChatCarousel = ({
  friendIdsToChatWith,
}: {
  friendIdsToChatWith: string[];
}) => {
  const layout = useLayoutContext();
  const navigation = useNavigation();

  return (
    <FlatList
      data={friendIdsToChatWith}
      renderItem={({ item }) => <CarouselItem id={item} />}
      keyExtractor={item => item}
      horizontal
      style={styles.list}
      ListHeaderComponent={
        <View style={[styles.itemContainer, { marginLeft: 20 }]}>
          <AddFriendIconButton
            size={40}
            onPress={() => {
              if (layout === Layouts.MOBILE) {
                navigation.navigate("AddFriendScreen");
              }
            }}
          />
          <Text>Add Friend</Text>
        </View>
      }
    />
  );
};
