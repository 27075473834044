import React from "react";
import { StyleSheet } from "react-native";
import { Card } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";

import { KButton, Body } from "components";
import { Routine } from "types/routines";
import { COLORS } from "utils/appStyles";
import { useNavigation } from "@react-navigation/native";
import { useDimensionsContext, useLayoutContext, Layouts } from "contexts";
import { useSelector } from "redux/store";
import { AnimatedInviteCard } from "components/HabitInvites/AnimatedInviteCard";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useHistory } from "utils/react-router";
import { shallowEqual } from "react-redux";
import { logTapRoutineInviteCard } from "utils/analytics";

const styles = StyleSheet.create({
  cover: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  arrowIcon: { paddingRight: 16, paddingLeft: 8 },
});

export const RoutineInviteCard = ({
  id,
  isShown,
  hideItem,
  showDismissButton = true,
}: {
  id: string;
  isShown;
  hideItem: (id: string) => void;
  showDismissButton?: boolean;
}) => {
  const navigation = useNavigation();
  const history = useHistory();
  const layout = useLayoutContext();
  const { width } = useDimensionsContext();
  const r: Routine = useSelector(
    state => state.firestore.data.routines[id],
    shallowEqual
  );

  // This shouldn't be needed but when Routines order changes,
  // redux-firestore has a bug where the id is undefined
  // https://github.com/prescottprue/redux-firestore/issues/292
  if (!r) {
    return null;
  }

  const onPress = () => {
    logTapRoutineInviteCard("learn_more", id);
    if (layout === Layouts.MOBILE) {
      navigation.navigate("RoutineOverviewScreen", {
        routineId: id,
      });
    } else {
      history.push(`/routine/${id}`);
    }
  };

  return (
    <AnimatedInviteCard isShown={isShown} width={width}>
      <TouchableOpacity onPress={onPress}>
        <Card.Cover source={{ uri: r.photoUrl }} style={styles.cover} />
        <Card.Title
          title={r.name}
          right={() => (
            <Ionicons
              name="ios-arrow-forward"
              size={15}
              style={styles.arrowIcon}
            />
          )}
        />
      </TouchableOpacity>
      <Card.Content>
        <Body numberOfLines={2}>{r.description}</Body>
      </Card.Content>
      <Card.Actions>
        <KButton
          label="Learn More"
          mode="text"
          color={COLORS.logoBlue}
          onPress={onPress}
        />
        {showDismissButton && (
          <KButton
            label="Dismiss"
            mode="text"
            color={COLORS.darkGray}
            onPress={() => {
              logTapRoutineInviteCard("dismiss", id);
              hideItem(id);
            }}
          />
        )}
      </Card.Actions>
    </AnimatedInviteCard>
  );
};
