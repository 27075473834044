import { StyleSheet } from "react-native";
import { useMemo } from "react";
import { useDimensionsContext } from "contexts";

//! Components that utilize this need to be wrapped in
// ViewWithDimensionsContext
export const useHabitGridStyles = () => {
  const { width, getScaledFontSize } = useDimensionsContext();

  const COLUMN_WIDTH = Math.min((width * 61) / 360, 80);
  const GRID_LEFT_WIDTH = Math.min(width - 4 * COLUMN_WIDTH + 1, 320);

  return {
    COLUMN_WIDTH,
    GRID_LEFT_WIDTH,
    fontSize: getScaledFontSize(0.87),
    styles: useMemo(
      () =>
        StyleSheet.create({
          column: {
            width: COLUMN_WIDTH,
          },
          dataCell: {
            height: COLUMN_WIDTH,
            width: COLUMN_WIDTH,
          },
          headerCell: {
            height: COLUMN_WIDTH + 10,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          },
          day: {
            height: COLUMN_WIDTH - 2,
            width: COLUMN_WIDTH - 2,
            borderRadius: 0,
            justifyContent: "center",
            alignItems: "center",
            // So that the first cell is up against edge of screen instead of padding
            alignSelf: "flex-end",
          },
        }),
      [COLUMN_WIDTH]
    ),
  };
};
