import React, { ReactNode } from "react";
import { useHabitGridStyles } from "hooks/habits/useHabitGridStyles";
import { View, Text, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Badge } from "react-native-paper";

const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
  titleText: { fontFamily: "OpenSans" },
  iconContainer: {
    position: "absolute",
    width: 22,
    height: 22,
    borderRadius: 50,
    right: -11,
    zIndex: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    shadowColor: "black",
    shadowOpacity: 0.2,
    shadowRadius: 2,
    shadowOffset: { width: 1, height: 0 },
    elevation: 3,
  },
  titleContainer: {
    backgroundColor: "white",
    justifyContent: "center",
  },
  badge: {
    position: "absolute",
    zIndex: 10,
    top: -3,
    right: -3,
  },
});

export const GenericHabitTitleRow = ({
  title,
  onPress,
  Icon,
  showBadge,
}: {
  title: string;
  onPress: () => void;
  Icon?: ReactNode;
  showBadge?: boolean;
}) => {
  const { COLUMN_WIDTH, fontSize } = useHabitGridStyles();

  return (
    <View style={styles.titleContainer}>
      <TouchableOpacity
        style={{
          height: COLUMN_WIDTH,
          justifyContent: "center",
          paddingHorizontal: 8,
          paddingVertical: 4,
        }}
        onPress={onPress}
      >
        <Text style={[styles.titleText, { fontSize }]} numberOfLines={2}>
          {title}
        </Text>
      </TouchableOpacity>
      {Icon && (
        <View style={styles.iconContainer}>
          {showBadge && <Badge size={8} style={styles.badge} />}
          <TouchableOpacity onPress={onPress}>{Icon}</TouchableOpacity>
        </View>
      )}
    </View>
  );
};
