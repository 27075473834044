import Constants from "expo-constants";
import React, { useState, useEffect, useMemo } from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  Linking,
  ScrollView,
  SafeAreaView,
} from "react-native";
import * as StoreReview from "expo-store-review";

import {
  InputActions,
  Loading,
  SettingSection,
  ButtonSetting,
  OpenMoreSetting,
  TextSetting,
  ToggleSetting,
} from "components";

import { useGetAuth, useGetFriends } from "redux/selectors";
import { NotificationType } from "types/friends";
import { Logger } from "utils/Logger";
import axios from "utils/axios";
import { uploadImage } from "utils/chat";
import {
  envIsDevelopment,
  API_HOST,
  APP_ENV,
  DISPLAY_VERSION,
} from "utils/constants";
import { signOut } from "utils/auth";
import { useFirebase } from "react-redux-firebase";
import { useString } from "hooks";
import { useLanguageContext } from "contexts";
import { languageDisplay } from "assets/i18n";
import { logAuthSignOut } from "utils/analytics";
import { ShareLink } from "components/Invite/ShareLink";
import { USER_PROFILE_URL, doShareInviteLink } from "utils/sharing";
import { useDispatch } from "react-redux";
import { FeedbackModal } from "components/Feedback/FeedbackModal";
import { isMobilePlatform } from "utils/helpers";
import { FeedbackSource } from "types/feedback";
const PROD_APP_NAME = "SnapHabit";

export const ProfileScreen = ({ navigation, route }) => {
  const { auth, profile } = useGetAuth();
  const { friends } = useGetFriends();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  //TODO: can remove this if only query for coach
  const coach = useMemo(() => friends.find(f => f.type), [friends]);
  const [image, setSelectedImage] = useState();
  const { language } = useLanguageContext();
  const s = useString("profileScreen");
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (image) {
      uploadImage(image, `profiles/${auth.uid}`).then(async url => {
        await firebase.updateAuth({
          photoURL: url,
        });
        axios
          .post(`${API_HOST}/updateProfile`, {
            photoURL: url,
          })
          .catch(error => Logger.error(error));
        setSelectedImage(null);
      });
    }
  }, [auth?.uid, firebase, image]);

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.headerContainer}>
          <Image source={{ uri: auth.photoURL }} style={styles.profileSize} />
          <View style={styles.imagePicker}>
            {image ? (
              <Loading />
            ) : (
              <InputActions
                selectedPhoto={image}
                setSelectedPhoto={setSelectedImage}
                inputActionsStyle={{}}
              />
            )}
          </View>
        </View>
        <Text style={styles.name}>{auth.displayName}</Text>
        <SettingSection label={s("addFriends")} style={{}}>
          <ShareLink
            link={`${USER_PROFILE_URL}${profile.handle}`}
            onPress={() => doShareInviteLink(profile, dispatch)}
            type="profile_link_widget"
            routeName={route.name}
          />
        </SettingSection>
        <SettingSection label={s("general")}>
          <TextSetting label={s("email")} content={auth.email} />
          <TextSetting
            label={s("profileId")}
            caption={s("addByUserName")}
            content={profile.handle}
          />
          <OpenMoreSetting
            label={s("archivedHabits")}
            onPress={() => {
              navigation.navigate("ArchiveScreen");
            }}
          />
          <OpenMoreSetting
            label={s("reorderYourHabits")}
            onPress={() => {
              navigation.navigate("HabitOrderingScreen");
            }}
          />
          <OpenMoreSetting
            label={s("notifications")}
            onPress={async () => {
              navigation.navigate("NotificationSettingsScreen");
            }}
          />
          <OpenMoreSetting
            label={s("language")}
            caption={languageDisplay[language]}
            onPress={() => {
              navigation.navigate("LanguageScreen");
            }}
          />
          <ToggleSetting
            label={s("visibleToFriendsOfFriends")}
            caption={s("helpsNewFriendsFindYou")}
            value={profile?.wantShowAsFriendOfFriends ?? true}
            onToggle={val => {
              firebase.firestore().doc(`users/${auth.uid}`).update({
                wantShowAsFriendOfFriends: val,
              });
            }}
          />
          {coach && (
            <ToggleSetting
              label={s("updatesFrom", coach.name)}
              value={coach.wantNotifications !== NotificationType.NONE}
              onToggle={val => {
                firebase
                  .firestore()
                  .doc(`users/${auth.uid}/friends/${coach.id}`)
                  .update({
                    wantNotifications: val
                      ? NotificationType.ALL
                      : NotificationType.NONE,
                  });
              }}
            />
          )}
        </SettingSection>

        {auth && !auth.isAnonymous && (
          <SettingSection label={s("appInfo")}>
            <OpenMoreSetting
              label={s("rateApp")}
              onPress={async () => {
                const canPrompt = await StoreReview.isAvailableAsync();
                if (canPrompt) {
                  StoreReview.requestReview();
                }
              }}
            />
            <OpenMoreSetting
              label={s("faq")}
              caption="eg. How do I start a group habit?"
              onPress={() => {
                Linking.openURL("https://snaphabit.app/faq");
              }}
            />
            <OpenMoreSetting
              label={s("sendUsFeedback")}
              //listing email as a caption because user
              //may not use default email client
              caption={!isMobilePlatform && "feedback@snaphabit.app"}
              onPress={() => {
                isMobilePlatform
                  ? setShouldShowModal(true)
                  : Linking.openURL(
                      "mailto:feedback@snaphabit.app?subject=App Feedback"
                    );
              }}
            />
            <TextSetting label={s("appVersion")} content={DISPLAY_VERSION} />

            <OpenMoreSetting
              label={s("privacyPolicyAndTermsOfUse")}
              caption={s("byUsingOurApp")}
              onPress={() => {
                Linking.openURL("https://www.snaphabit.app/privacy.html");
              }}
            />
          </SettingSection>
        )}

        {envIsDevelopment() && (
          <SettingSection label={s("devOptions")}>
            <TextSetting label={s("env")} content={APP_ENV} />
            <OpenMoreSetting
              label={s("uid")}
              caption={auth.uid}
              onPress={() => {
                const { Clipboard } = require("react-native");
                Clipboard.setString(auth.uid);
              }}
            />
            <TextSetting label={s("host")} content={API_HOST} />
            <TextSetting
              label={s("anonUser")}
              content={auth.isAnonymous ? "Yes" : "No"}
            />
            {Constants.manifest.name !== PROD_APP_NAME && (
              <TextSetting
                label={s("appName")}
                content={Constants.manifest.name}
              />
            )}
            <OpenMoreSetting
              label={s("clearAsyncStorage")}
              caption="Clears keys prefixed with @Habit"
              onPress={async () => {
                const AsyncStorage = require("@react-native-community/async-storage")
                  .default;
                const keys = await AsyncStorage.getAllKeys();
                AsyncStorage.multiRemove(
                  keys.filter(k => k.startsWith("@Habit"))
                );
              }}
            />
          </SettingSection>
        )}

        <SettingSection>
          <ButtonSetting
            color="#ffffff"
            backgroundColor="#e3242b"
            label={s("signOut")}
            icon="md-log-out"
            onPress={() => {
              logAuthSignOut(auth.uid);
              signOut(auth.uid, firebase);
            }}
          />
        </SettingSection>

        {shouldShowModal && (
          <FeedbackModal
            setShouldShowModal={setShouldShowModal}
            source={FeedbackSource.PROFILE}
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  name: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 30,
  },
  button: {
    marginBottom: 20,
    marginHorizontal: 20,
  },
  container: { backgroundColor: "white", flex: 1 },
  headerContainer: {
    width: 140,
    alignSelf: "center",
    marginTop: 50,
  },
  profileSize: {
    width: 140,
    height: 140,
    borderRadius: 140,
  },
  imagePicker: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "white",
    width: 33,
    height: 33,
    borderRadius: 33,
    justifyContent: "center",
    alignItems: "center",
  },
});
