import React, { useEffect, useState } from "react";
import { FlatList } from "react-native-gesture-handler";
import { StyleSheet, Platform } from "react-native";
import { Channel } from "stream-chat";
import { Loading } from "components/Loading";
import { KChatPreview } from "components/Chat/KChatPreview";
import { isMobilePlatform } from "utils/helpers";
import { StartChatCarousel } from "components/Chat/StartChatCarousel";
import { useLayoutContext, Layouts } from "contexts/LayoutContext";
import { useFriendIdsWithoutChat } from "hooks/chat/useFriendIdsWithoutChat";
import { NoChatItem } from "components/Chat/NoChatItem";
import { ContentCenteredView } from "components/UI/ContentCenteredView";
import { KButton } from "components/UI/KButton";
import { useString } from "hooks";
import { SectionTitle } from "components/UI/Typography";
import { useNavigation } from "@react-navigation/native";
import { AddFriendWidget } from "components/Friends/AddFriendWidget";

const styles = StyleSheet.create({
  listEmptyContainer: {
    margin: 20,
  },
  textCenter: {
    textAlign: "center",
  },
  button: {
    margin: 20,
  },
});
/**
 * References
 * https://github.com/GetStream/stream-chat-react-native/blob/fc07b174adb8e432b5ec743e17fd9b441ea0cb00/src/components/ChannelListMessenger.js#L21
 * https://github.com/GetStream/stream-chat-react-native/blob/fc07b174adb8e432b5ec743e17fd9b441ea0cb00/src/components/ChannelPreviewMessenger.js#L65
 */
export const KChatChannelList = ({
  channels,
  loadingChannels,
  loading,
  setActiveChannel,
  loadNextPage,
  loadingNextPage,
  refreshList,
  refreshing,
}: {
  channels: Channel[];
  loadingChannels: boolean;
  loading?: boolean; // on web
  setActiveChannel: (c: Channel) => void;
  loadNextPage: (something: boolean) => void;
  loadingNextPage: boolean;
  refreshList: () => void;
  refreshing: boolean;
}) => {
  const layout = useLayoutContext();
  const friendIdsToChatWith = useFriendIdsWithoutChat(channels);
  const [data, setData] = useState<(Channel | string)[]>([]);
  const s = useString("chatScreen");
  const navigation = useNavigation();

  useEffect(() => {
    // Merge channels and friends
    if (layout === Layouts.WIDE && channels) {
      setData([...channels, ...friendIdsToChatWith]);
    } else {
      setData(channels);
    }
  }, [channels, friendIdsToChatWith, layout]);

  if (loadingChannels || loading) {
    return <Loading />;
  }

  return (
    <FlatList
      data={data}
      renderItem={({ item: channel }) => {
        if (typeof channel === "string") {
          return <NoChatItem id={channel} />;
        }
        return (
          <KChatPreview
            channel={channel as Channel}
            setActiveChannel={setActiveChannel}
          />
        );
      }}
      showsVerticalScrollIndicator={Platform.OS === "web" ? false : null}
      onEndReached={() => {
        //TODO - pagination on web
        if (isMobilePlatform) {
          loadNextPage(false);
        }
      }}
      onRefresh={() => refreshList()}
      refreshing={refreshing}
      onEndReachedThreshold={2}
      ListHeaderComponent={
        layout === Layouts.MOBILE && (
          <StartChatCarousel friendIdsToChatWith={friendIdsToChatWith} />
        )
      }
      contentContainerStyle={
        layout === Layouts.MOBILE && {
          marginHorizontal: 10,
        }
      }
      ListEmptyComponent={() => (
        <ContentCenteredView style={styles.listEmptyContainer}>
          <SectionTitle style={styles.textCenter}>
            {s("listEmptyStartChat")}
          </SectionTitle>
          <AddFriendWidget />
        </ContentCenteredView>
      )}
      ListFooterComponent={() => {
        if (loadingNextPage) {
          return <Loading />;
        }

        return null;
      }}
      keyExtractor={item => item?.cid ?? item}
    />
  );
};
