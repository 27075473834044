import { useMemo } from "react";
import { useSelector } from "redux/store";
import { habitByIndexSelector } from "redux/selectors";
import { Logger } from "utils/Logger";
import { createSelector } from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";

const getDayByHabitIdSelector = ({ habitid, date, ownHabit }) =>
  createSelector(
    state =>
      ownHabit
        ? state.userDays?.[habitid]?.[date]
        : state.sharedDays.habitToDayMap?.[habitid]?.[date],
    day => day || { habitid }
  );

const getDayByHabitIndexSelector = ({ habitIndex, date, ownHabit, friendId }) =>
  createSelector(
    habitByIndexSelector(habitIndex, friendId),
    state => (ownHabit ? state.userDays : state.sharedDays.habitToDayMap),
    (habit, habitToDayMap) => {
      return { ...habitToDayMap?.[habit?.id]?.[date], habitid: habit?.id };
    }
  );

// HabitDetailsView uses habitid since we already have it
// HabitGrid uses habitIndex since allowing user re-ordering
// depends on it
export const useGetDay = ({
  habitIndex,
  habitid,
  date,
  ownHabit,
  friendId,
}: {
  date: string; // MM-DD-YYYY
  ownHabit: boolean;
} & (
  | {
      habitid: string;
      habitIndex?: undefined;
      friendId?: undefined;
    }
  | { habitIndex: number; friendId?: string; habitid?: string }
)) => {
  const selector = useMemo(() => {
    if (habitid) {
      return getDayByHabitIdSelector({ habitid, date, ownHabit });
    }

    return getDayByHabitIndexSelector({ habitIndex, date, ownHabit, friendId });
  }, [habitid, habitIndex, date, ownHabit, friendId]);

  const day = useSelector(selector, shallowEqual);

  Logger.log(
    "useDay rerunning",
    "habitid",
    habitid,
    "habitIndex",
    habitIndex,
    date,
    "ownHabit?",
    ownHabit,
    day
  );

  return day;
};
