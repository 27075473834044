//! KEEP THIS IN SYNC WITH routines/types/routines.ts
import moment from "moment";

export enum RoutineType {
  Ongoing = "dynamic",
  Static = "static", // fixed length
}

export enum PublishedStatus {
  DRAFT = 0, // Doesn't appear anywhere except for editor
  DEV = 1, // Only Dev
  BETA = 2, // Dev and Beta
  PUBLISHED = 3, // Dev, Beta, and Production
}

export type Routine = {
  id?: string;
  uid?: string; // unique identifier so that we don't duplicate routines
  name: string;
  description: string;
  emoji: string;
  photoUrl: string;
  canUsersShareProgressWithOwner: boolean;
  ownerUid: string;
  ownerName: string;
  ownerAvatar: string;
  ownerDescription: string;
  timePerDay: number; // in minutes
  type: RoutineType;
  contentId: string; // doc id of the content @ routine/id/content/contentId
  ordering: number; // what number this routine should be in the routine carousel
  publishedStatus: PublishedStatus;
};

export interface StaticRoutine extends Routine {
  type: RoutineType.Static;
  length: number; // number of days
}

export const isStaticRoutine = (r: Routine): r is StaticRoutine =>
  r.type === RoutineType.Static;

export type RoutineContent = {
  day: number;
  title: string;
  body: string;
  media: HostedMedia[];
  urls: WebsitePreview[];
  notePlaceholder?: string;
  photosForSharing?: string[];
};

export type HostedMedia = {
  id: string;
  url: string;
  filename: string;
  size: number;
  type: string;
  width?: number;
  height?: number;
  thumbnails: {
    small: Thumbnail;
    large: Thumbnail;
    full: Thumbnail;
  };
};

export type WebsitePreview = {
  author: string;
  date: string;
  title: string;
  description: string;
  publisher: string;
  url: string;
  logo?: WebsitePreviewImage;
  image?: WebsitePreviewImage;
  screenshot?: WebsitePreviewImage;
};

type WebsitePreviewImage = {
  height: number;
  width: number;
  url: string;
  size: number;
  type: string;
};

type Thumbnail = {
  url: string;
  width: number;
  height;
};

export type SelectedRoutineDay = {
  index: number; // index that we're currently displaying
  routineDays: {
    day: number;
    moment: moment.Moment; // moment for that day
  }[];
  maxIndex: number; // maximum index we can show
};
