import moment from "moment";
import React, { useState } from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import { TabView } from "react-native-tab-view";

import { useGetNotes } from "redux/selectors";
import { Habit } from "types/habits";
import { Loading } from "components/Loading";
import { KTabBar } from "components/UI/KTabBar";
import { HabitTitle } from "components/UI/ListElements";
import { Logger } from "utils/Logger";
import { Footnote } from "components/UI/Typography";
import { DatePicker } from "./DatePicker";
import { HabitDay } from "./HabitDay";
import { Month } from "./Month";
import { NotesGraphs } from "./NotesGraphs";
import { NotesList } from "./NotesList";
import { logToggleHabitTabView } from "utils/analytics";

const initialMonth = moment();

export const HabitDetailsView = ({
  habit,
  habitid,
  ownHabit,
  friendid,
}: {
  habit: Habit;
  habitid: string;
  ownHabit: boolean;
  friendid?: string;
}) => {
  const [month, setMonth] = useState(initialMonth);

  //TODO: can push this down into other components
  const { isLoaded, notes } = useGetNotes({ habitid, ownHabit });

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "notes", title: "Notes" },
    { key: "graph", title: "Graphs" },
  ]);

  const renderScene = ({ route }) => {
    switch (route.key) {
      case "notes":
        return (
          <NotesList
            notes={notes}
            habitid={habitid}
            ownHabit={ownHabit}
            friendid={friendid}
          />
        );
      case "graph":
        return <NotesGraphs ownHabit={ownHabit} notes={notes} />;
      default:
        return null;
    }
  };

  Logger.log("HabitDetailsView re-rendering");

  const { streak = 0, createdAt, sharing } = habit;
  const firstSubtitle = `Current streak: ${streak}`;

  // only show how many people sharing with if its your own habit
  // a bit of privacy weirdness if show how many other habits people are sharing with
  // maybe can add some other interesting metadata here
  let secondSubtitle = "";
  if (ownHabit) {
    const shareCount = Object.keys(sharing).filter(friend => sharing[friend])
      .length;

    // TODO: consider splitting friends eg. Sharing with 4 friends (2 pending)
    // would need to pull friends from FireStore Context
    secondSubtitle = `Sharing with ${
      shareCount === 1 ? "1 friend" : `${shareCount} friends`
    }`;
  } else {
    // ?timestamp formatting for createdAt
    // createdAt.toDate() generates a JS date object
    // could format from there, but is difficults
    const createdDay = createdAt
      ? moment(createdAt.toDate()).format("MMM D YYYY")
      : "old habit - no data";
    secondSubtitle = `Started: ${createdDay}`;
  }

  const subtitle = `${firstSubtitle} · ${secondSubtitle}`;

  return (
    <View style={styles.container}>
      <View style={styles.monthContainer}>
        <View style={styles.row}>
          <HabitTitle habit={habit} habitid={habitid} ownHabit={ownHabit} />
        </View>
        <Footnote>{subtitle}</Footnote>
        <DatePicker
          date={month.format("MMMM YYYY")}
          dateUp={() => setMonth(month => month.clone().add(1, "month"))}
          dateDown={() => setMonth(month => month.clone().subtract(1, "month"))}
        />
        <Month
          habit={habit}
          habitid={habitid}
          month={month}
          ownHabit={ownHabit}
          friendid={friendid}
          DayComponent={HabitDay}
        />
      </View>
      {isLoaded ? (
        <TabView
          renderTabBar={props => <KTabBar {...props} />}
          navigationState={{ index, routes }}
          onIndexChange={index => {
            logToggleHabitTabView(index === 0 ? "notes" : "graphs"); // log only toggles, not the initial scene rendered
            setIndex(index);
          }}
          renderScene={renderScene}
          initialLayout={{ width: Dimensions.get("window").width }}
          tabBarPosition="top"
          lazy
        />
      ) : (
        <Loading />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  monthContainer: {
    marginTop: 10,
    marginHorizontal: 10,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
