import React, { useRef, useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Platform,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  TextInput,
  Text,
} from "react-native";
import { ImagePickerResult } from "expo-image-picker/build/ImagePicker.types";
import { useActionSheet } from "@expo/react-native-action-sheet";
import moment from "moment";

import { useGetDay, useGetAuth } from "redux/selectors";
import { NoteMedia, Note } from "types/habits";
import firebase from "utils/firebase";
import { uploadImage } from "utils/chat";
import {
  ContentCenteredView,
  KKeyboardAvoidingView,
  KMediaViewer,
  KImage,
  ImageThumbnail,
  InputActions,
} from "components";
import { COLORS } from "utils/appStyles";
import { Logger } from "utils/Logger";
import { usePrevious } from "hooks/usePrevious";

export enum SaveState {
  IS_DIRTY = 0,
  IS_SAVING,
  SAVED,
  UNTOUCHED,
}

const IMAGE_LENGTH = 100;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: { fontSize: 20, textAlign: "center", margin: 4 },
  imageContainer: {
    borderBottomWidth: 1,
  },
  image: {
    height: IMAGE_LENGTH,
    width: IMAGE_LENGTH,
  },
  addMedia: {
    height: 75,
    width: 75,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  saveContainer: {
    backgroundColor: COLORS.disabled,
    borderBottomWidth: 1,
  },
  saveText: {
    textAlign: "center",
    padding: 4,
  },
  input: {
    flex: 1,
    fontSize: 24,
    lineHeight: 24,
    paddingTop: 18,
    paddingBottom: 18,
    paddingHorizontal: 12,
  },
  toolbar: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-around",
    padding: 4,
    alignItems: "center",
    borderTopWidth: 1,
  },
  inputActionsStyle: {
    flexBasis: "12%",
    alignItems: "center",
  },
  editButton: {
    backgroundColor: "white",
    borderWidth: 1,
  },
  button: {
    width: "15%",
  },
  toggleContainer: {
    width: "40%",
    alignItems: "center",
  },
});

export const NoteEditor = ({
  dayid,
  habitid,
  date,
  ownHabit,
  isEditable,
  render,
}: {
  dayid: string;
  habitid: string;
  date: string; //MM-DD-YYYY
  ownHabit: boolean;
  isEditable: boolean;
  render?: ({ isEditable, saveState, onChangeText, note }) => JSX.Element;
}) => {
  //! Day may not exist if shared days aren't loaded
  //! So do not assume we have a day (unless its your own note)
  const day = useGetDay({ habitid, date, ownHabit });

  const { auth } = useGetAuth();

  const timeoutRef = useRef(null);
  const [lastSaved, setLastSaved] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);
  const [enableAutoSave, setEnableAutoSave] = useState(false);
  const [saveState, setSaveState] = useState<SaveState>(SaveState.UNTOUCHED);
  const inputRef = useRef(null);
  const [selectedMedia, setSelectedMedia] = useState<ImagePickerResult>();
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);

  // Note parameters
  const [note, setNote] = useState<string>("");
  const [isPublic, setIsPublic] = useState(true);
  const [media, setMedia] = useState<NoteMedia[]>([]);

  // Media Modal
  const [imageViewerIsVisible, setImageViewerIsVisible] = useState<boolean>(
    false
  );
  const [imageViewerIndex, setImageViewerIndex] = useState<number>(0);

  // Handle media deletion
  const { showActionSheetWithOptions } = useActionSheet();

  const openActionSheet = index => {
    const options = ["Delete", "Cancel"];
    const cancelButtonIndex = 1;
    const destructiveButtonIndex = 0;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      buttonIndex => {
        if (buttonIndex === 0) {
          setMedia(media => {
            media.splice(index, 1);
            return [...media];
          });
        }
      }
    );
  };

  //! Autofocus issue with stack navigator causing double navigation when
  // autofocus on input or if focused too quickly
  // https://github.com/react-navigation/react-navigation/issues/6778
  useEffect(() => {
    let timeout;
    if (isEditable) {
      timeout = setTimeout(() => inputRef.current?.focus(), 400);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isEditable]);

  const previousDayId = usePrevious(dayid);
  const previousNote = usePrevious(note);
  const previousMedia = usePrevious(media);

  useEffect(
    function saveNote() {
      let mounted = true;

      function save() {
        if (mounted) {
          setSaveState(SaveState.IS_SAVING);
        }

        firebase
          .firestore()
          .doc(`notes/${habitid}/notesDay/${dayid}`)
          .set({
            note,
            isPublic,
            media,
            date: day.date,
            absoluteDay: day.absoluteDay,
            uid: auth.uid,
            habitid,
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            if (mounted) {
              setLastSaved(Date.now());
              setSaveState(SaveState.SAVED);
            }
          });
      }

      const shouldSave =
        ownHabit &&
        previousDayId === dayid &&
        (note || media.length > 0) &&
        (previousNote !== note || previousMedia !== media);

      function debouncedSave() {
        if (enableAutoSave && shouldSave) {
          setSaveState(SaveState.IS_DIRTY);
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          timeoutRef.current = setTimeout(() => {
            save();
          }, 500);
        }
      }

      debouncedSave();

      return () => {
        mounted = false;
      };
    },
    [
      enableAutoSave,
      ownHabit,
      auth.uid,
      day,
      dayid,
      habitid,
      isPublic,
      media,
      note,
      previousDayId,
      previousNote,
      previousMedia,
    ]
  );

  useEffect(
    function loadNoteFromDB() {
      const resetState = () => {
        clearTimeout(timeoutRef.current);
        setSaveState(SaveState.UNTOUCHED);
        setEnableAutoSave(false);
        setIsPublic(true);
        setNote("");
        setMedia([]);
        setIsLoading(false);
      };

      resetState();
      setIsLoading(true);
      firebase
        .firestore()
        .doc(`notes/${habitid}/notesDay/${dayid}`)
        .get()
        .then(ds => {
          if (ds.exists) {
            const prevNote = ds.data() as Note;
            setNote(prevNote.note);
            setIsPublic(prevNote.isPublic);
            setMedia(prevNote.media || []);
            setIsLoading(false);
          } else {
            // This case matters for web -- if already have a note
            // open and navigating to another note, we need to reset the state
            // if it doesn't exist
            resetState();
          }
        })
        .catch(error => {
          //! Our firestore rules don't allow us to get a doc
          //! if it doesn't exist the rule looks at the doc's uid field
          //! for now, if error is thrown assume that the doc doesn't exist...
          // TODO edit rule to allow read if have access to the day
          Logger.log(error);
          resetState();
        });
    },
    [dayid, habitid]
  );

  useEffect(
    function uploadMedia() {
      if (selectedMedia && selectedMedia.cancelled === false) {
        setIsUploadingMedia(true);
        uploadImage(
          selectedMedia,
          `notes/${auth.uid}/${habitid}/${dayid}`
        ).then(url => {
          setEnableAutoSave(true);
          setMedia(media => {
            const newItem = { ...selectedMedia };
            delete newItem.cancelled;
            // on Web, the URI is the entire file base64 encoded instead of
            // just a file path. Delete this otherwise we're essentially
            // storing the file in Firestore and will hit the ~1MB limit for
            // a doc!
            if (Platform.OS === "web") {
              if (newItem.uri.startsWith("data:video")) {
                newItem.type = "video";
                newItem.height = 1080; //! PLACEHOLDERS!
                newItem.width = 1920; // TODO: figure out the right values to place here
              } else {
                newItem.type = "image";
                newItem.height = 1080;
                newItem.width = 1920;
              }

              delete newItem.uri;
            }

            const newMedia = [
              ...media,
              {
                lastUpdated: Date.now(),
                ...newItem,
                url,
              },
            ];

            return newMedia;
          });
          setIsUploadingMedia(false);
          setSelectedMedia(null);
        });
      }
    },
    [auth, dayid, habitid, selectedMedia]
  );

  if (isLoading) {
    return (
      <ContentCenteredView>
        <ActivityIndicator />
      </ContentCenteredView>
    );
  }

  const onChangeText = (text: string) => {
    if (!enableAutoSave) setEnableAutoSave(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setNote(text);
  };

  if (render) {
    return render({
      isEditable,
      saveState,
      onChangeText,
      note,
    });
  }

  return (
    <KKeyboardAvoidingView
      render={() => (
        <>
          <KMediaViewer
            isVisible={imageViewerIsVisible}
            setIsVisible={setImageViewerIsVisible}
            index={imageViewerIndex}
            imageUrls={media.map(m => ({
              url: "",
              height: m.height,
              width: m.width,
              props: {
                local: m.uri,
                remote: m.url,
                type: m.type,
                style: { height: m.height, width: m.width },
              },
            }))}
          />
          <View style={styles.saveContainer}>
            {isEditable && (
              <Text style={styles.saveText}>
                {saveState === SaveState.IS_DIRTY
                  ? "Keep going!"
                  : saveState === SaveState.IS_SAVING
                  ? "Saving..."
                  : saveState === SaveState.SAVED && lastSaved
                  ? `Saved at ${moment(lastSaved).format("h:mm")}`
                  : "Just type. Changes auto save."}
              </Text>
            )}
          </View>
          <View style={styles.imageContainer}>
            <FlatList
              data={media}
              renderItem={({ item, index }) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setImageViewerIndex(index);
                      setImageViewerIsVisible(true);
                    }}
                    onLongPress={() => {
                      if (isEditable) {
                        openActionSheet(index);
                      }
                    }}
                  >
                    <KImage
                      local={item.uri}
                      remote={item.url}
                      type={item.type}
                      style={{ height: 100, width: 100 }}
                    />
                  </TouchableOpacity>
                );
              }}
              keyExtractor={item => item.lastUpdated.toString()}
              initialNumToRender={5}
              maxToRenderPerBatch={5}
              windowSize={5}
              getItemLayout={(_data, index) => ({
                length: IMAGE_LENGTH,
                offset: IMAGE_LENGTH * index,
                index,
              })}
              horizontal
              ListFooterComponent={
                selectedMedia && selectedMedia.cancelled === false ? (
                  <ImageThumbnail
                    selectedPhoto={selectedMedia}
                    setSelectedPhoto={setSelectedMedia}
                    isUploading={isUploadingMedia}
                    imageStyle={styles.image}
                  />
                ) : ownHabit && isEditable && media.length > 0 ? (
                  <View style={[styles.addMedia, { width: 100 }]}>
                    {!selectedMedia && (
                      <InputActions
                        selectedPhoto={selectedMedia}
                        setSelectedPhoto={setSelectedMedia}
                        inputActionsStyle={{}}
                      />
                    )}
                  </View>
                ) : null
              }
              ListEmptyComponent={
                ownHabit &&
                isEditable && (
                  <View
                    style={[styles.addMedia, { width: selectedMedia ? 0 : 75 }]}
                  >
                    {!selectedMedia && (
                      <InputActions
                        selectedPhoto={selectedMedia}
                        setSelectedPhoto={setSelectedMedia}
                        inputActionsStyle={{}}
                      />
                    )}
                  </View>
                )
              }
            />
          </View>
          <TextInput
            ref={inputRef}
            // autoFocus={editable} -- for some reason, this causes the toolbar to be hidden
            // instead, use a useEffect
            editable={isEditable}
            value={note}
            style={styles.input}
            multiline
            autoCompleteType="off"
            onChangeText={onChangeText}
            placeholder={isEditable ? "Add note..." : "Nothing here!"}
          />
        </>
      )}
    />
  );
};
